import React from "react";
import CartridgesV2Component from "../Components/CartridgeType/cartridgesV2";

import DashboardLayout from "../Components/Layouts/DashboardLayout";

const CartridgesPage = () => {
  return (
    <DashboardLayout title="Cartridges V2 | H2Y0">
      <CartridgesV2Component />
    </DashboardLayout>
  );
};

export default CartridgesPage;
