import React, { useEffect, useState } from "react";
import { Table, Modal } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import ComposeDrinkLayout from "../Layouts/ComposeDrinkLayout";

// Actions
import { openFormModal } from "../../redux/modals/modals.actions";
import { getDrinks, toggleDrinkModal, secondaryDrinkModal } from "../../redux/drinks/drinks.actions";

// Form Constants
import form from "../../redux/modals/constants";

import DrinkIngredient from "./drinkIngredient";
import axiosInstance from "../../axios";
import SecondaryDrink from "./secondaryDrink";

const ComposeDrinkComponent = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    status: false,
  })
  const [Errors, setErrors] = useState({
    noName: false
  })
  const { drinks } = useSelector((state) => state);
  const { cartridgeType } = useSelector((state) => state);
  const [drinkIngredients, setDrinkIngredients] = useState([])

  const { loading, error } = cartridgeType;

  useEffect(() => {
  }, [dispatch]);

  const setDrinkItems = (data) => {
    delete data._rev
    let ingredients = drinkIngredients
    const filtered = ingredients.filter((item) => {
      return item._id != data._id
    })
    filtered.push(data)
    setDrinkIngredients(filtered)
    setState((prevState) => {
      return {
        ...prevState,
        ingredients: filtered
      }
    })
  }
  const removeFromDrink = (data) => {
    let ingredients = drinkIngredients
    const filtered = ingredients.filter((item) => {
      return item._id != data._id
    })
    setDrinkIngredients(filtered)
    setState((prevState) => {
      return {
        ...prevState,
        ingredients: filtered
      }
    })
  }

  if (error) {
    toast.error(error);
  }

  let renderDrinkIngredients;

  if (cartridgeType.cartridgeTypes && cartridgeType.cartridgeTypes.length) {
    renderDrinkIngredients = cartridgeType.cartridgeTypes.map((value) => {

      return (
        <DrinkIngredient key={value._id} value={value} setDrinkItems={setDrinkItems} removeFromDrink={removeFromDrink} />
      );
    });
  } else {
    renderDrinkIngredients = (
      <Table.Row>
        <Table.Cell>No cartridge types found.</Table.Cell>
      </Table.Row>
    );
  }

  const onChangeName = (name) => {
    console.log(name)
    setState((prevState) => {
      return {
        ...prevState,
        name
      }
    })
  }
  const saveDrink = async (name) => {
    if (!state.name) {
      setErrors((prevState) => {
        return {
          ...prevState,
          noName: true
        }
      })
    } else {
      const data = await axiosInstance.post('/drinks/add', state)
      dispatch(getDrinks());
      dispatch(toggleDrinkModal())
    }
  }
  const onSaveData = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data
      }
    })
    dispatch(secondaryDrinkModal())
    console.log(state)
  }
  const toggleStatus = (status) => {
    setState((prevState) => {
      return {
        ...prevState,
        status
      }
    })
    console.log(status)
  }
  return (
    <ComposeDrinkLayout
      onChangeHwVersion={() => { }}
      title="Compose Drink"
      loading={loading}
      openAddModal={() => dispatch(openFormModal(form.NEW_DRINK))}
      onChangeName={onChangeName}
      saveDrink={saveDrink}
      setStatus={toggleStatus}
      secondaryDrinkModal={() => dispatch(secondaryDrinkModal())}
    >
      <Modal
        size={"medium"}
        open={drinks.secondaryModal}
        onClose={() => dispatch(secondaryDrinkModal())}
      >
        <SecondaryDrink onSaveData={onSaveData} />
      </Modal>
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>In Drink?</Table.HeaderCell>
            <Table.HeaderCell>Intensity 1</Table.HeaderCell>
            <Table.HeaderCell>Intensity 2</Table.HeaderCell>
            <Table.HeaderCell>Intensity 3</Table.HeaderCell>
            <Table.HeaderCell>Is Boost?</Table.HeaderCell>
            <Table.HeaderCell>Boost Level1</Table.HeaderCell>
            <Table.HeaderCell>Boost Level2</Table.HeaderCell>
            <Table.HeaderCell>Boost Level3</Table.HeaderCell>
            <Table.HeaderCell>Default boost Level</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderDrinkIngredients}</Table.Body>
      </Table>
    </ComposeDrinkLayout>
  );
};

export default ComposeDrinkComponent;
