import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// Layout
import ModalFormLayout from "../Layouts/ModalFormLayout";
import { toast } from "react-toastify";


import { closeFormModal } from "../../redux/modals/modals.actions";
import { editIngredient } from "../../redux/ingredients/ingredients.actions";


import { Form, Button } from "semantic-ui-react";
import { units } from './helpers';


const IngredientEditForm = () => {
  const { register, handleSubmit, errors } = useForm();

  const { loading, success, ingredients, editedIngredient } = useSelector((state) => state.ingredients);
  const dispatch = useDispatch();

  const [ingredient, setIngredient] = useState(null)

  const onSubmit = (data) => {

    dispatch(editIngredient(ingredient[0]));

  };

  if (editedIngredient && !ingredient) {
    const IngredientData = ingredients.filter((ingredient) => {
      return ingredient._id === editedIngredient
    })
    setIngredient(IngredientData)
  }

  if (success) {
    toast.success(success);
    dispatch(closeFormModal());
  }

  const onChange = (e, field) => {
    switch (field) {
      case "name":
        setIngredient([{ ...ingredient[0], "name": e.target.value }])
        break;
      case "dailyValue":
        setIngredient([{ ...ingredient[0], "dailyValue": e.target.value }])
        break;
      case "unit":
        setIngredient([{ ...ingredient[0], "unit": e.target.value }])
        break;
      default:
        return null;
    }
  }
  return (
    <ModalFormLayout title="Edit Ingredient">
      <Form
        noValidate
        size="large"
        loading={loading}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Field required>
          <label>Name</label>
          <input
            type="text"
            name="name"
            onChange={(e)=>onChange(e, "name")}
            value={ingredient? ingredient[0].name : null}
            ref={register({
              required: true,
            })}
          />
          {errors.name && errors.name.type === "required" && (
            <p>Name is required</p>
          )}
        </Form.Field>
        <Form.Field required>
          <label>Daily Value</label>
          <input
            type="text"
            name="dailyValue"
            onChange={(e) => onChange(e, "dailyValue")}
            value={ingredient ? ingredient[0].dailyValue : ''}
            ref={register({
              required: false,
            })}
          />
          {errors.dailyValue && errors.dailyValue.type === "required" && (
            <p>Daily Value</p>
          )}
        </Form.Field>
        <Form.Field required>
          <label>Unit</label>

          <select
            name="unit"
            onChange={(e)=>onChange(e, "unit")}
            value={ingredient? ingredient[0].unit : null}
            ref={register({
              required: true,
            })}
          >
            <option value=""></option>
            {
              units.map((unit) => {
                return (
                  <option key={unit.name} value={unit.value}>{unit.name}</option>
                )
              })
            }
          </select>
        </Form.Field>
        {errors.unit && errors.unit.type === "required" && (
          <p>Unit is required</p>
        )}

        <Button primary type="submit" fluid>
          Save
        </Button>
      </Form>
    </ModalFormLayout>
  );
};

export default IngredientEditForm;
