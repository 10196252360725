import React, { useEffect, useState } from "react";
import { Header, Table } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Popup, Modal, List } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import { fetchCartridgeTypes,fetchCartridgeTypesV2, deleteCartridgeType, setEditedCartridgeType } from "../../redux/cartridgeType/cartridgeType.actions";
import { openFormModal } from "../../redux/modals/modals.actions";

// Form Constants
import form from "../../redux/modals/constants";
import ActionButtonGroupV2 from "../Common/ActionButtonGroupV2";

const Row = ({value, onEdit}) => {
  const dispatch = useDispatch();
  const { cartridgeType } = useSelector((state) => state);
    const { ingredients } = useSelector((state) => state);
    
    const [flowRate,setFlowRate] = useState(1)
    const [servingSize,setServingSize] = useState(1)
    const [dose,setDose] = useState([])
    const [dose1,setDose1] = useState(1)
    const [dose2,setDose2] = useState(1)
    const [dose3,setDose3] = useState(1)
    const [dosingSpeed,setDosingSpeed] = useState(1)

    const { loading, error } = cartridgeType;
    
    const { register, handleSubmit, errors } = useForm();

  const onDeleteCrt = (id) => {
    dispatch(deleteCartridgeType(id))
  }

    useEffect(() => {
      setDose1(value.dose[0])
      setDose2(value.dose[1])
      setDose3(value.dose[2])
      setFlowRate(value.flowRate)
      setServingSize(value.servingSize)
      setDosingSpeed(value.dosingSpeed)
    dispatch(fetchCartridgeTypesV2());
  }, []);

      return (
        <Table.Row key={value._id}>
          <Table.Cell>{value._id}</Table.Cell>
          <Table.Cell>
            <Header as="h4" image>
              <Header.Content>{value.name}</Header.Content>
            </Header>
          </Table.Cell>
              {/* <Table.Cell>{value.dosingSpeed }</Table.Cell> */}
              <Table.Cell>
                  <input
                      style={{ width: "70px", height: "30px", borderStyle: "solid", borderRadius: "5px", borderWidth: "2px", borderColor: 'grey' }}
                      type="text"
                      name="flowrate"
              value={dosingSpeed}
              onChange={(e)=>{setDosingSpeed(e.target.value)}}
                  />
              </Table.Cell>
              <Table.Cell>
                  <input
                      style={{ width: "70px", height: "30px",marginRight:"4px", borderStyle: "solid", borderRadius: "5px", borderWidth: "2px", borderColor: 'grey' }}
                      type="text"
                      name="flowrate"
                      value={dose1}
                      onChange={(e)=>{setDose1(parseInt(e.target.value) || 0)}}
                  />
                  <input
                      style={{ width: "70px", height: "30px",marginRight:"4px", borderStyle: "solid", borderRadius: "5px", borderWidth: "2px", borderColor: 'grey' }}
                      type="text"
                      name="flowrate"
                      value={dose2}
                      onChange={(e)=>{setDose2(parseInt(e.target.value) || 0)}}
                  />
                  <input
                      style={{ width: "70px", height: "30px",marginRight:"4px", borderStyle: "solid", borderRadius: "5px", borderWidth: "2px", borderColor: 'grey' }}
                      type="text"
                      name="flowrate"
                      value={dose3}
                      onChange={(e)=>{setDose3(parseInt(e.target.value) || 0)}}
                  />
              </Table.Cell>
          <Table.Cell>
            <ActionButtonGroupV2 id={value._id} onDelete={onDeleteCrt} data={{ flowRate, dose:[dose1,dose2,dose3], servingSize, dosingSpeed }} onEdit={onEdit} color={ (dose1 !== value.dose[0] || dose2 !== value.dose[1] || dose3 !== value.dose[2] || dosingSpeed !== value.dosingSpeed) ? true : false}/>
          </Table.Cell>
        </Table.Row>
      );

};

export default Row;
