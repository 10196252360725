import React from "react";
import { Modal } from "semantic-ui-react";

const ModalFormLayout = ({ title, children }) => {
  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
    </>
  );
};

export default ModalFormLayout;
