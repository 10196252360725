import types from "./types";

export const openFormModal = (form) => {
  console.log("Form is: ", form);
  return {
    type: types.OPEN_FORM_MODAL,
    payload: form,
  };
};

export const closeFormModal = () => {
  return {
    type: types.CLOSE_FORM_MODAL,
  };
};
