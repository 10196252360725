import types from "./types";

const initialState = {
  loading: false,
  error: null,
  success: null,
  languages: [],
  editedLanguage: null,
};

const i18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case types.CLEAR_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.I18N_NEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case types.I18N_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "New language added",
      };
    case types.I18N_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not create new language",
        success: null,
      };
    case types.I18N_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "Language successfully edited!",
      };
    case types.I18N_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not edit language",
        success: null,
      };
    case types.I18N_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        i18n: [],
      };
    case types.I18N_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not fetch languages, please try again",
        success: null,
        i18n: [],
      };
    case types.I18N_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        languages: action.payload,
      };
    case types.I18N_EDIT:
      return {
        ...state,
        editedI18n: action.payload,
      };
    case types.I18N_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "Language Deleted Successfully",
      };

    default:
      return state;
  }
};

export default i18nReducer;
