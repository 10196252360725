import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

// Route Wrappers
import PrivateRoute from "./PrivateRoute";
import UnprotectedRoute from "./UnprotectedRoute";

// Pages
import * as page from "../Pages";

// Paths
import {
    HOME,
    INGREDIENTS,
    CARTRIDGES,
    CARTRIDGES_V2,
    DRINKS,
    I18N,
    INSTRUCTIONS,
    ALTERNATIVES
} from "./paths";

const AppRouter = () => {
    const auth = useSelector((state) => state.auth);

    // destructure from auth
    const { isAuthenticated, loading } = auth;

    const dashPages = [
        {
            path: INGREDIENTS,
            component: page.IngredientsPage,
        },
        {
            path: CARTRIDGES,
            component: page.CartridgesPage,
        },
        {
            path: CARTRIDGES_V2,
            component: page.CartridgesV2Page,
        },
        {
            path: DRINKS,
            component: page.Drinks,
        },
        {
            path: I18N,
            component: page.LanguagesPage,
        },
        {
            path: INSTRUCTIONS,
            component: page.InstructionsPage,
        },
        {
            path: ALTERNATIVES,
            component: page.CartridgeAlternativesPage,
        }
    ];

    const renderPages = dashPages.map((page) => {
        return (
            <PrivateRoute
                key={page.path}
                path={page.path}
                component={page.component}
                isAuthenticated={isAuthenticated}
                loading={loading}
            />
        );
    });

    return (
        <Router>
            <Switch>
                {renderPages}

                <UnprotectedRoute
                    exact
                    path={HOME}
                    component={page.HomePage}
                    isAuthenticated={isAuthenticated}
                    loading={loading}
                />
                <Route component={page.NotFoundPage} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
