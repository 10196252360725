const types = {
  I18N_REQUEST: "I18N_REQUEST",
  I18N_FAILURE: "I18N_FAILURE",
  I18N_SUCCESS: "I18N_SUCCESS",

  I18N_NEW_REQUEST: "I18N_NEW_REQUEST",
  I18N_NEW_SUCCESS: "I18N_NEW_SUCCESS",
  I18N_NEW_FAILURE: "I18N_NEW_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",

  I18N_EDIT: "I18N_EDIT",
  I18N_EDIT_SUCCESS: "I18N_EDIT_SUCCESS",
  I18N_EDIT_FAILIURE: "I18N_EDIT_FAILIURE",

  I18N_DELETE_SUCCESS: "I18N_DELETE_SUCCESS"
};

export default types;
