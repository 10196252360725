import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { signOutUser } from "../../redux/auth/auth.actions";

const UserDropDown = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const { user } = auth;

  return (
    <Dropdown text={`${user.firstName} ${user.lastName}`}>
      <Dropdown.Menu>
        <Dropdown.Item text="Profile" />
        <Dropdown.Item text="Logout" onClick={() => dispatch(signOutUser())} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropDown;
