import types from "./type";

const initialState = {
  loading: false,
  error: null,
  success: null,
  instructions: [],
};

const instructionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        instructions: action.payload,
      };
    default:
      return state;
  }
};

export default instructionsReducer;
