import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
// Layout

// Actions
import { closeFormModal } from "../../redux/modals/modals.actions";
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import axiosInstance from "../../axios";


const LanguageStructureForm = () => {
  const { i18n: { languages }} = useSelector((state) => state);
  const [obj, setObj] = useState({changeThis: "and this", andThis: "and this or remove this line, if you wont need it"})
  const [canSubmit, setCanSubmit] = useState(true)
  const dispatch = useDispatch()

  const onChange = ({jsObject, error}) => {
    if (error) {
      setCanSubmit(false)
    }

    if (!jsObject) return
    setObj(jsObject)

    const keys = Object.keys(jsObject)

    const existingKeys = keys.filter((key) => {
      if (languages?.en.translations[key]) {
        return key
      }
    })

    const nonStringKeys = keys.filter((key) => {
      if (typeof jsObject[key] !== 'string') {
        return key
      }
    })

    if (existingKeys.length) {
      existingKeys.forEach((key) => {
        toast.error(`The key ${key} already exists, rename it.`)
        setCanSubmit(false)
      })
      return 
    }

    if (nonStringKeys.length || error) {
      nonStringKeys.forEach((key) => {
        toast.error(`${key} should only hold text.`)
        setCanSubmit(false)
      })
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }

  const onClick = () => {
    if (canSubmit) {
      axiosInstance.put("/i18n/editStructure", obj)
      dispatch(closeFormModal())
    } else {
      toast.error('Fix any errors before submitting')
    }
  }

  return (
    <div style={{width: 600, backgroundColor: '#fdfdfd'}}>
      
      <JSONInput
        id='addToStructure'
        placeholder={obj}
        locale={locale}
        height="800px"
        width="600px"
        style={{body: {fontSize: 15}}}
        onChange={onChange}
      />
      <Button color="primary" onClick={onClick} style={{width: 600}} disabled={!canSubmit}>Add to structure</Button>  
    </div>      
  );
};

export default LanguageStructureForm;
