import axios from "../../axios";
import types from "./type";


const successInstructions = (instructions) => {
  return {
    type: types.INSTRUCTIONS_SUCCESS,
    payload: instructions,
  };
};

export const fetchInstructions = () => async (dispatch) => {
//   dispatch(requestCartridgeTypes());
    try {
      
    const { data } = await axios.get("/instructions");

    dispatch(successInstructions(data.data));
    console.log(data.data)
  } catch (err) {
    // dispatch(failureCartridgeTypes(err.message));
    // dispatch(clearErrors());
  }
};

export const addInstruction = (instruction) => async (dispatch) => {
    try {
        const { data } = await axios.post("/instructions/add", instruction);
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}

export const deleteInstructions = (instruction) => async (dispatch) => {
    try {
        const { data } = await axios.post("/instructions/delete", instruction);
        dispatch(fetchInstructions())
        console.log(data)
    } catch (error) {
        console.log(error)
    }
}