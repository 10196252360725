import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Table, Button } from "semantic-ui-react";
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import { fetchAlternatives } from "../../redux/alternatives/alternatives.actions";
import { fetchCartridgeTypes } from "../../redux/cartridgeType/cartridgeType.actions";
import form from "../../redux/modals/constants";
import { openFormModal } from "../../redux/modals/modals.actions";
import { deleteCartridgeAlternative } from "../../redux/alternatives/alternatives.actions";

const CartridgeAlternativesComponent = () => {
  const dispatch = useDispatch();
  const alternatives = useSelector((state) => state.alternatives);
  const { cartridgeType } = useSelector((state) => state);
  const { loading, cartridgeTypes } = cartridgeType;

  const cartridgeTypesMappedById = cartridgeTypes.length
    ? cartridgeTypes.reduce((acc, cartridgeType) => {
        return {
          ...acc,
          [cartridgeType._id]: cartridgeType.name,
        };
      })
    : {};

  useEffect(() => {
    dispatch(fetchAlternatives());
    dispatch(fetchCartridgeTypes());
  }, [dispatch]);

  const onDelete = (id) => {
    dispatch(deleteCartridgeAlternative(id));
  };

  const renderBody = () => {
    if (!cartridgeTypes.length) {
      return <></>;
    }

    return (
      <>
        {alternatives.cartridgeAlternatives?.map((alternative) => {
          console.log("alternative", alternative);

          const cType = cartridgeTypes.find((cartridgeType) => {
            return cartridgeType._id === alternative.cartridge_type_id;
          });

          return (
            <Table.Row key={alternative.id}>
              <Table.Cell>{cType?.name || ""}</Table.Cell>
              <Table.Cell>{alternative.cartridge_type_id}</Table.Cell>
              <Table.Cell>
                {JSON.parse(alternative.types_to_substitute).map((type) => {
                  return (
                    <p>
                      {type} ({cartridgeTypesMappedById[type]})
                    </p>
                  );
                })}
              </Table.Cell>
              <Table.Cell>
                <Button
                  icon="trash"
                  color="red"
                  size="mini"
                  onClick={() => {
                    onDelete(alternative.id);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </>
    );
  };

  return (
    <DashboardContentLayout
      title="Cartridge Alternatives"
      loading={loading}
      openAddModal={() =>
        dispatch(openFormModal(form.NEW_CARTRIDGE_ALTERNATIVE))
      }
    >
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Cartridge name</Table.HeaderCell>
            <Table.HeaderCell>Cartridge code</Table.HeaderCell>
            <Table.HeaderCell>Substituted by</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderBody()}</Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default CartridgeAlternativesComponent;
