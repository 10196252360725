import React, { useEffect } from "react";
import { Header, Table, Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateCartridge } from "../../redux/cartridgeType/cartridgeType.actions";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import { fetchCartridgeTypes, deleteCartridgeType, setEditedCartridgeType } from "../../redux/cartridgeType/cartridgeType.actions";
import { openFormModal } from "../../redux/modals/modals.actions";

// Form Constants
import form from "../../redux/modals/constants";

const CartridgeTypeComponent = () => {
  const dispatch = useDispatch();
  const { cartridgeType } = useSelector((state) => state);
  const { ingredients } = useSelector((state) => state);

  const { loading, error } = cartridgeType;

  const onDeleteCrt = (id) => {
    dispatch(deleteCartridgeType(id))
  }

  const onEdit = (id) => {
    dispatch(setEditedCartridgeType(id))
    dispatch(openFormModal(form.EDIT_CARTRIDGE_TYPE))
  }

  const editIsDisabled = (cartridgeTypeData, isDisabled) => {
      dispatch(updateCartridge({
        ...cartridgeTypeData,
        isDisabled,
      }));
  }

  useEffect(() => {
    dispatch(fetchCartridgeTypes());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  let renderCartridgeType;

  if (cartridgeType.cartridgeTypes && cartridgeType.cartridgeTypes.length) {

    // sort cartridge types by name alphabetically

    cartridgeType.cartridgeTypes.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });


    renderCartridgeType = cartridgeType.cartridgeTypes.map((value) => {
      let ingredientsContained;

      if (value.ingredients && value.ingredients.length) {
        ingredientsContained = value.ingredients.map((ingredient) => {
          return `${ingredient.name},  `;
        });
      } else {
        ingredientsContained = "None";
      }

      return (
        <Table.Row key={value._id}>
          <Table.Cell>{value._id}</Table.Cell>
          <Table.Cell>
            <Header as="h4" image>
              <Header.Content>{value.name}</Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>{value.volume}</Table.Cell>
          <Table.Cell>{value.dosingSpeed}</Table.Cell>
          <Table.Cell>{value.color}</Table.Cell>
          <Table.Cell>{JSON.stringify(value.dose)}</Table.Cell>
          <Table.Cell>{value.priceInEuroCents ? value.priceInEuroCents : 'Not set'}</Table.Cell>
          <Table.Cell>
            <Button
              toggle
              active={!value.isDisabled}
              onClick={() => editIsDisabled(value, !value.isDisabled)}
            >
              {value.isDisabled ? "TEST" : "Active"}
            </Button>
          </Table.Cell>
          <Table.Cell>
            <ActionButtonGroup id={value._id} onDelete={onDeleteCrt} onEdit={ onEdit}/>
          </Table.Cell>
        </Table.Row>
      );
    });
  } else {
    renderCartridgeType = (
      <Table.Row>
        <Table.Cell>No cartridge types found.</Table.Cell>
      </Table.Row>
    );
  }
  return (
    <DashboardContentLayout
      title="Cartridge Types"
      loading={loading || ingredients.loading}
      openAddModal={() => dispatch(openFormModal(form.NEW_CARTRIDGE_TYPE))}
    >
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Cartridge code</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Volume (mm)</Table.HeaderCell>
            <Table.HeaderCell>Dosing Speed</Table.HeaderCell>
            <Table.HeaderCell>Color</Table.HeaderCell>
            <Table.HeaderCell>Dose (miliseconds)</Table.HeaderCell>
            <Table.HeaderCell>Price (Euro cents)</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderCartridgeType}</Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default CartridgeTypeComponent;
