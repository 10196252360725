import React, { useState, useEffect } from 'react';
import { Table, Header, Checkbox, Dropdown } from 'semantic-ui-react';

const DrinkIngredient = ({ value, setDrinkItems, removeFromDrink }) => {
    const [state, setState] = useState({
        inDrink: false,
        isBoost: false,
        boostLevel1: 0,
        boostLevel2: 0,
        boostLevel3: 0,
        intensity1: 0,
        intensity2: 0,
        intensity3: 0,
        defaultBoostLevel: null,
    })

    const options = [
        { key: 1, text: 'Boost 1', value: 1 },
        { key: 2, text: 'Boost 2', value: 2 },
        { key: 3, text: 'Boost 3', value: 3 },
      ]

    useEffect(() => {
        setState((prevState) => {
            return {
                ...value,
                ...prevState,
                intensity1: value.dose[0],
                intensity2: value.dose[1],
                intensity3: value.dose[2]
            }
        })
    }, [])

    useEffect(() => {
        if (state.inDrink || state.isBoost) {
            setDrinkItems(state)
        } else {
            removeFromDrink(state)
        }
    }, [state])

    const onChangeDrink = (checked) => {
        setState((prevState) => { return { ...prevState, inDrink: checked } })
    }


    return (
        <Table.Row key={value._id}>
            <Table.Cell>
                <Header as="h4" image>
                    <Header.Content>{value.name}</Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>
                <Checkbox
                    toggle
                    onChange={(e, { checked }) => onChangeDrink(checked)}
                />
            </Table.Cell>
            <Table.Cell>
                <input
                    disabled={!state.inDrink}
                    style={{ ...StyleSheet.input, borderColor: state.inDrink ? 'lightblue' : 'snow' }}
                    type="text"
                    name="intensity1"
                    value={state.intensity1}
                    onChange={(e) => {
                        setState((prevState) => {
                            return { ...prevState, intensity1: +e.target.value };
                        })
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <input
                    disabled={!state.inDrink}
                    style={{ ...StyleSheet.input, borderColor: state.inDrink ? 'lightblue' : 'snow' }}
                    type="text"
                    name="intensity2"
                    value={state.intensity2}
                    onChange={(e) => {
                        setState((prevState) => {
                            return { ...prevState, intensity2: +e.target.value };
                        })
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <input
                    disabled={!state.inDrink}
                    style={{ ...StyleSheet.input, borderColor: state.inDrink ? 'lightblue' : 'snow' }}
                    type="text"
                    name="intensity3"
                    value={state.intensity3}
                    onChange={(e) => {
                        setState((prevState) => {
                            return { ...prevState, intensity3: +e.target.value };
                        })
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <Checkbox
                    toggle
                    onChange={(e, { checked }) =>
                        setState((prevState) => {
                            return { ...prevState, isBoost: checked };
                        })
                    }
                />
            </Table.Cell>
            <Table.Cell>
                <input
                    disabled={!state.isBoost}
                    style={{ ...StyleSheet.input, borderColor: state.isBoost ? 'lightblue' : 'snow' }}
                    type="text"
                    name="BoostLevel1"
                    value={value.boostLevel1}
                    onChange={(e) => {
                        setState((prevState) => {
                            return { ...prevState, boostLevel1: +e.target.value };
                        })
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <input
                    disabled={!state.isBoost}
                    style={{ ...StyleSheet.input, borderColor: state.isBoost ? 'lightblue' : 'snow' }}
                    type="text"
                    name="BoostLevel2"
                    value={value.boostLevel2}
                    onChange={(e) => {
                        setState((prevState) => {
                            return { ...prevState, boostLevel2: +e.target.value };
                        })
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <input
                    disabled={!state.isBoost}
                    style={{ ...StyleSheet.input, borderColor: state.isBoost ? 'lightblue' : 'snow' }}
                    type="text"
                    name="BoostLevel3"
                    value={value.boostLevel3}
                    onChange={(e) => {
                        setState((prevState) => {
                            return { ...prevState, boostLevel3: +e.target.value };
                        })
                    }}
                />
            </Table.Cell>
            <Table.Cell>
                <Dropdown clearable value={state.defaultBoostLevel} options={options} selection onChange={(e, { value }) => {
                            setState((prevState) => {
                                return { ...prevState, defaultBoostLevel: +value };
                            })
                    }}/>
            </Table.Cell>
        </Table.Row>
    );
}

const StyleSheet = {
    input: {
        alignSelf: 'center',
        width: "70px",
        height: "30px",
        marginTop: '0px',
        marginLeft: '5px',
        borderStyle: "solid",
        borderRadius: "5px",
        borderWidth: "1px",
        borderColor: 'lightblue',
        backgroundColor: 'transparent'
    }
}
export default DrinkIngredient