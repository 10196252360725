import axios from "../../axios";
import types from "./types";

const requestI18n = () => {
  return {
    type: types.I18N_REQUEST,
  };
};

const failureI18n = (err) => {
  return {
    type: types.I18N_FAILURE,
    payload: err,
  };
};

const successI18n = (i18n) => {
  return {
    type: types.I18N_SUCCESS,
    payload: i18n,
  };
};

const clearErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};

const clearSuccess = () => {
  return {
    type: types.CLEAR_SUCCESS,
  };
};

const requestI18nNew = () => {
  return {
    type: types.I18N_NEW_REQUEST,
  };
};

const failureI18nNew = () => {
  return {
    type: types.I18N_NEW_FAILURE,
  };
};

const successI18nNew = () => {
  return {
    type: types.I18N_NEW_SUCCESS,
  };
};

const languageEdited = (id) => {
  return {
    type: types.I18N_EDIT,
    payload: id,
  };
};

const failureI18nEdit = () => {
  return {
    type: types.I18N_EDIT_FAILIURE,
  };
};

const successI18nEdit = () => {
  return {
    type: types.I18N_EDIT_SUCCESS,
  };
};

const successI18nDelete = () => {
  return {
    type: types.I18N_DELETE_SUCCESS,
  };
};

export const setEditedLanguage = (data) => (dispatch) => {
  dispatch(languageEdited(data))
}

export const fetchLanguages = () => async (dispatch) => {
  dispatch(requestI18n());
  try {
    const { data } = await axios.get("/i18n");
    dispatch(successI18n(data));
  } catch (err) {
    dispatch(failureI18n(err.message));
    dispatch(clearErrors());
  }
};

export const newLanguage = (input) => async (dispatch) => {
  dispatch(requestI18nNew());
  try {
    await axios.post("/i18n/add", { ...input });

    dispatch(successI18nNew());
    dispatch(clearSuccess());
    dispatch(fetchLanguages());
  } catch (err) {
    dispatch(failureI18nNew(err.message));
    dispatch(clearErrors());
  }
};

export const editLanguage = (input) => async (dispatch) => {
  dispatch(requestI18nNew());
  try {
    await axios.put("/i18n/edit", { ...input });

    dispatch(successI18nEdit());
    dispatch(clearSuccess());
    dispatch(fetchLanguages());
  } catch (err) {
    dispatch(failureI18nEdit(err.message));
    dispatch(clearErrors());
  }
};

export const deleteLanguage = (input) => async (dispatch) => {
  dispatch(requestI18nNew());
  try {
    await axios.delete(`/i18n/delete/${input}`);
    dispatch(successI18nDelete());
    dispatch(clearSuccess());
    dispatch(fetchLanguages());
  } catch (err) {
    dispatch(failureI18nNew(err.message));
    dispatch(clearErrors());
  }
};
