import React from "react";
import CartridgeAlternativesComponent from "../Components/CartridgeAlternatives/index";

import DashboardLayout from "../Components/Layouts/DashboardLayout";

const CartridgesPage = () => {
  return (
    <DashboardLayout title="Cartridge Alternatives | H2Y0">
      <CartridgeAlternativesComponent />
    </DashboardLayout>
  );
};

export default CartridgesPage;
