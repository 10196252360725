import React from "react";
import InternationalizationComponent from "../Components/Internationalization/Internationalization";

import DashboardLayout from "../Components/Layouts/DashboardLayout";

const CartridgesPage = () => {
  return (
    <DashboardLayout title="Internationalization | H2Y0">
      <InternationalizationComponent />
    </DashboardLayout>
  );
};

export default CartridgesPage;
