import React, {useState, useReducer, createRef} from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Sticky, Grid, Rail, Ref } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
// Layout
import ModalFormLayout from "../Layouts/ModalFormLayout";

// Actions
import { closeFormModal } from "../../redux/modals/modals.actions";
import { newLanguage, editLanguage } from "../../redux/i18n/i18n.actions";

const LanguageForm = ({isNew}) => {
  const { register, handleSubmit, errors } = useForm();
  const { i18n: { languages, editedI18n, loading, success }} = useSelector((state) => state);

  const [state, updateState] = useReducer(
    (state, updates) => ({ ...state, ...updates }),
    isNew ? languages?.en?.translations : Object.entries(languages).filter(lang  => {
      return lang[1].id === editedI18n
    })[0][1]?.translations
  );
  const dispatch = useDispatch();
  
  const onSubmit = () => {
    dispatch(isNew ? newLanguage({language: state.lang, translation: state}) : editLanguage({id: editedI18n, translation: state}));
  };

  if (success) {
    toast.success(success);
  }

  const onChange = (e, key, depth) => {
    const makeObject = (i, parent) => {
      if (depth[i] === undefined) {
        return e.target.value
      }
      const pnt = parent[depth[i]]

      if (Array.isArray(parent)) {
        const arr = [...parent]
        arr[depth[i]] = makeObject(i + 1,  pnt)
        return arr
      }

      return {...parent, [depth[i]]: makeObject(i + 1, pnt) }
    }

    const updatedState = makeObject(0, state)
    updateState(updatedState)
  }


  const renderObjectForm = (obj, name = '', depth = []) => {
    const keys = Object.keys(obj)
    
    return (
      <div style={{paddingLeft: 5}} key={name || JSON.stringify(depth)}>
        <h4 style={{fontWeight: 600}}>{name}</h4>
        {keys.map((key) => {
          if (obj[key] === null) {
            return
          }
          if (typeof obj[key] === "string" ) {
            return (
              <>
                <label>{key}</label>
                {renderInput(key, obj[key], [...depth, key])}
              </>
            )
          }

          if (Array.isArray(obj[key])) {
            return renderArrayForm(obj[key], key, [...depth, key])
          }

          if (typeof obj[key] === "object" && obj.hasOwnProperty(key)) {
            return renderObjectForm(obj[key], key, [...depth, key])
          }
        })}
      </div>
    )
  }

  const renderArrayForm = (arr, name = null, depth) => {
    return (
      <>
       <label>{name}</label>
        {arr.map((item, i) => {

          if (typeof item === "string" ) {
            return <div key={i.toString()}>{renderInput(i, item, [...depth, i])}</div>
          }

          if (Array.isArray(item)) {
            return <div key={i.toString()}>{renderArrayForm(item, null, [...depth, i])}</div>
          }

          if (typeof item === "object") {
            return <div key={i.toString()}>{renderObjectForm(item, i, [...depth, i])}</div>
          }
        })}
      </>
    )
  }

  const renderInput = (key, value, depth) => {
    return (
      <>
        <input
          name={`${key}`}
          value={value}
          type="text"
          onChange={(e)=>onChange(e, key, depth)}
          ref={register({
            required: true,
          })}
        />
        {errors[key] && errors[key].type === "required" && (
          <p>{key} is required</p>
        )}
      </>
    )
  }

  const renderFormFields = () => {
    return (
      <Form
        noValidate
        size="large"
        loading={loading}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid centered columns={2}>
          <Grid.Column>{renderObjectForm(state ?? {})}</Grid.Column>
          <Grid.Column>
            <Button primary type="submit" fluid style={{position: 'sticky', top: 20}}>
              Save
            </Button>
          </Grid.Column>
        </Grid>
      </Form>
    )
  }

  return (
    <ModalFormLayout title={isNew ? "New Language" : "Edit Language"}>
      {renderFormFields()}
    </ModalFormLayout>
  );
};

export default LanguageForm;
