import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Popup, Modal, List, Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Layout
import ModalFormLayout from "../Layouts/ModalFormLayout";

// Actions
import { updateCartridge } from "../../redux/cartridgeType/cartridgeType.actions";
import { closeFormModal } from "../../redux/modals/modals.actions";
import { fetchIngredients } from "../../redux/ingredients/ingredients.actions";
const compensationOptions = [
    { key: 'inc', value: 'more', text: 'More' },
    { key: 'dec', value: 'less', text: 'Less' },
]
const multipleCartridgeOptions = [
    { key: 'true', value: true, text: 'Yes' },
    { key: 'false', value: false, text: 'No' },
]
const CartridgeTypeEditForm = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchIngredients());
    }, [dispatch]);

    const { register, handleSubmit, errors } = useForm();

    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
    } = useForm();

    const [cartIngredients, setCartIngredients] = useState([]);
    const [cartridgeType, setCartridgeType] = useState(null)

    const [open, setOpen] = useState(false);
    const [bagOptions, setBagOptions] = useState('250, 300, 320');

    const { loading, success, editedCartridgeType, cartridgeTypes } = useSelector((state) => state.cartridgeType);
    const ingredients = useSelector((state) => state.ingredients);

    useEffect(() => {
        const availlableOptions =cartridgeType[0].bagVolumeOptions || bagOptions
        setBagOptions(availlableOptions);
    }, []);

    if (editedCartridgeType && !cartridgeType) {
        const CartridgeData = cartridgeTypes.filter((cartridgeType) => {
            return cartridgeType._id === editedCartridgeType
        })

        setCartridgeType(CartridgeData)
        setCartIngredients(cartIngredients.concat(CartridgeData[0].ingredients))
    }

    const onChange = (e, field) => {
        console.log(field)
        switch (field) {
            case "name":
                setCartridgeType([{ ...cartridgeType[0], "name": e.target.value }])
                break;
            case "dosingSpeed":
                setCartridgeType([{ ...cartridgeType[0], "dosingSpeed": e.target.value }])
                break;
            case "volume":
                setCartridgeType([{ ...cartridgeType[0], "volume": e.target.value }])
                break;
            case "dose":
                setCartridgeType([{ ...cartridgeType[0], "dose": e.target.value }])
                break;
            case "bagVolumeOptions":
                setBagOptions(e.target.value)
                setCartridgeType([{ ...cartridgeType[0], "bagVolumeOptions": e.target.value.split(',').map(item => +item.trim()).filter(item => !isNaN(item)) }])
                break;
            case "color":
                setCartridgeType([{ ...cartridgeType[0], "color": e.target.value }])
                break;
            case "density":
                setCartridgeType([{ ...cartridgeType[0], "density": e.target.value }])
                break;
            case "paragraph":
                setCartridgeType([{ ...cartridgeType[0], "paragraph": e.target.value }])
                break;
            case "title":
                setCartridgeType([{ ...cartridgeType[0], "title": e.target.value }])
                break;
            case "price":
                setCartridgeType([{ ...cartridgeType[0], "priceInEuroCents": e.target.value }])
                break;
            case "compensationPercentage":
                setCartridgeType([{ 
                    ...cartridgeType[0],
                    compensation: {
                        ...cartridgeType[0].compensation,
                        compensationPercentage: e.target.value
                    }
                    }])
                    break;
            case "compensationChange":
                setCartridgeType([{
                    ...cartridgeType[0],
                    compensation: {
                        ...cartridgeType[0].compensation,
                        compensationChange: e
                    }
                }])
                break;
            case "multipleInstallations":
                console.log(e)
                setCartridgeType([{
                    ...cartridgeType[0],
                    multipleInstallations: e
                }])
                break;
            default:
                return null;
        }
    }

    const updateIngredient = (_id, whichvalue, newvalue, which2, new2) => {
        let index = cartIngredients.findIndex((x) => x._id === _id);

        if (index !== -1) {
            let temporaryarray = cartIngredients.slice();
            temporaryarray[index][whichvalue] = newvalue;
            temporaryarray[index][which2] = new2;
            setCartIngredients(temporaryarray);
        } else {
            //TODO Check how id parsed below is used in server
            setCartIngredients([...cartIngredients, { _id, quantity: newvalue, dailypercentage: +new2 }]);
        }
    };

    const removeIngredient = (id) => {
        setCartIngredients(
            cartIngredients.filter((ingredient) => ingredient._id !== id)
        );
    };

    const ingredientSubmit = (data) => {
        updateIngredient(data._id, "quantity", data.quantity, 'dailypercentage', data.dailypercentage);
        setOpen(false);
    };

    const onSubmit = (data) => {
        dispatch(updateCartridge({
            ...cartridgeType[0],
            dose: JSON.parse(`[${cartridgeType[0].dose}]`),
            ingredients: cartIngredients,
            title: cartridgeType[0].title || '',
            paragraph: cartridgeType[0].paragraph || ''
        }));
    };

    if (success) {
        toast.success(success);
        dispatch(closeFormModal());
    }

    let selectedIngredients;

    if (cartIngredients && cartIngredients.length) {
        selectedIngredients = cartIngredients.map((value) => {

            const SelectIngredient = ingredients.ingredients.filter((ingredient) => {
                return ingredient._id === value._id
            })

            return (
                <List.Item key={value._id}>
                    <List.Content floated="right">
                        <Popup
                            content="Remove ingredient"
                            trigger={
                                <Button
                                    circular
                                    icon="remove"
                                    onClick={() => removeIngredient(value._id)}
                                />
                            }
                        />
                    </List.Content>
                    <List.Content>
                        <List.Header>Ingredient Id: {value._id}</List.Header>
                        <List.Description>Amount per Serving: {value.quantity}</List.Description>
                        <List.Description>Dailypercentage: {value.dailypercentage}</List.Description>
                        <List.Description>Name: {SelectIngredient[0] ? SelectIngredient[0].name : value.name}</List.Description>
                    </List.Content>
                </List.Item>
            );
        });
    } else {
        selectedIngredients = "No ingredients selected";
    }
    console.log(ingredients)
    return (
        <ModalFormLayout title="Edit Cartridge Type">
            <Form
                noValidate
                size="large"
                loading={loading || ingredients.loading}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Form.Field required>
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        onChange={(e) => onChange(e, "name")}
                        value={cartridgeType ? cartridgeType[0].name : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.name && errors.name.type === "required" && (
                        <p>Cartridge type name is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Tittle</label>
                    <input
                        type="text"
                        name="title"
                        onChange={(e) => onChange(e, "title")}
                        value={cartridgeType ? cartridgeType[0].title : ''}
                        ref={register({
                            required: false,
                        })}
                    />
                    {errors.title && errors.title.type === "required" && (
                        <p>Cartridge tittle is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Paragraph</label>
                    <input
                        type="text"
                        name="paragraph"
                        onChange={(e) => onChange(e, "paragraph")}
                        value={cartridgeType ? cartridgeType[0].paragraph : ''}
                        ref={register({
                            required: false,
                        })}
                    />
                    {errors.paragraph && errors.paragraph.type === "required" && (
                        <p>Cartridge paragraph is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Volume</label>
                    <input
                        type="text"
                        name="volume"
                        onChange={(e) => onChange(e, "volume")}
                        value={cartridgeType ? cartridgeType[0].volume : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.volume && errors.volume.type === "required" && (
                        <p>Volume is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Price (Euro cents)</label>
                    <input
                        type="number"
                        name="price"
                        onChange={(e) => onChange(e, "price")}
                        value={cartridgeType ? cartridgeType[0].priceInEuroCents : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.price && errors.price.type === "required" && (
                        <p>Pricec is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Dosing Speed</label>
                    <input
                        type="text"
                        name="dosingSpeed"
                        onChange={(e) => onChange(e, "dosingSpeed")}
                        value={cartridgeType ? cartridgeType[0].dosingSpeed : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.dosingSpeed && errors.dosingSpeed.type === "required" && (
                        <p>Dosing speed is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Color Code (Hexadecimal)</label>
                    <input
                        type="text"
                        name="color"
                        onChange={(e) => onChange(e, "color")}
                        value={cartridgeType ? cartridgeType[0].color : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.color && errors.color.type === "required" && (
                        <p>Color Code is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Dose ie. [1000,2000,3000]</label>
                    <input
                        type="text"
                        name="dose"
                        onChange={(e) => onChange(e, "dose")}
                        value={cartridgeType ? cartridgeType[0].dose : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.dose && errors.dose.type === "required" && (
                        <p>Dose is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Bag Volume options ( 250,300,320)</label>
                    <input
                        type="text"
                        name="bagVolumeOptions"
                        onChange={(e) => onChange(e, "bagVolumeOptions")}
                        value={bagOptions}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.dose && errors.dose.type === "required" && (
                        <p>Volume options are required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Density (Weight per 100ml)</label>
                    <input
                        type="text"
                        name="density"
                        onChange={(e) => onChange(e, "density")}
                        value={cartridgeType ? cartridgeType[0].density : null}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.density && errors.density.type === "required" && (
                        <p>Density is required</p>
                    )}
                </Form.Field>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                    <Form.Field required>
                        <label>Compensation (%)</label>
                        <input
                            type="text"
                            name="compensationPercentage"
                            onChange={(e) => onChange(e, "compensationPercentage")}
                            value={cartridgeType && cartridgeType[0].compensation ? cartridgeType[0].compensation.compensationPercentage : null}
                            ref={register({
                                required: true,
                            })}
                        />
                        {errors.density && errors.density.type === "required" && (
                            <p>Density is required</p>
                        )}
                    </Form.Field>
                    <Form.Field required>
                    <label>Compensation Error</label>
                    <Dropdown 
                        placeholder={cartridgeType && cartridgeType[0].compensation ? cartridgeType[0].compensation.compensationChange : 'Change'} 
                        search 
                        selection 
                        options={compensationOptions} 
                        onChange={(e, e2)=> 
                        onChange(e2.value, "compensationChange")} 
                        />
                    {errors.density && errors.density.type === "required" && (
                        <p>Density is required</p>
                    )}
                </Form.Field>

                </div>
                <Form.Field required>
                    <label>Allow multiple installations in device</label>
                    <Dropdown 
                        placeholder={cartridgeType && cartridgeType[0].multipleInstallations ? 'Yes' : 'No'} 
                        search 
                        selection 
                        options={multipleCartridgeOptions} 
                        onChange={(e, e2)=> 
                        onChange(e2.value, "multipleInstallations")} 
                        />
                    {errors.density && errors.density.type === "required" && (
                        <p>Density is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Ingredients</label>

                    <List divided verticalAlign="middle">
                        {selectedIngredients}
                    </List>
                    <Popup
                        content="Add new ingredient"
                        trigger={
                            <Button
                                circular
                                name="add"
                                type="button"
                                icon="add"
                                onClick={() => setOpen(true)}
                            />
                        }
                    />
                </Form.Field>

                <Button primary name="submit" type="submit" fluid>
                    Save
                </Button>
            </Form>
            <Modal onClose={() => setOpen(false)} open={open} size="small">
                <Modal.Header>Add Ingredient</Modal.Header>
                <Modal.Content>
                    <Form
                        loading={loading || ingredients.loading}
                        onSubmit={handleSubmit2(ingredientSubmit)}
                    >
                        <Form.Field required>
                            <label>Ingredient</label>
                            <select
                                name="_id"
                                ref={register2({
                                    required: true,
                                })}
                            >
                                {ingredients.ingredients.map((value) => {
                                    return (
                                        <option key={value._id} value={value._id}>
                                            {value.name} ({value.unit})
                                        </option>
                                    );
                                })}
                            </select>
                            {errors2.id && errors2.id.type === "required" && (
                                <p>Ingredient is required</p>
                            )}
                        </Form.Field>
                        <Form.Field required>
                            <label>Quantity</label>
                            <input
                                type="text"
                                name="quantity"
                                ref={register2({
                                    required: true,
                                })}
                            />
                            {errors2.quantity && errors2.quantity.type === "required" && (
                                <p>Quantity is required</p>
                            )}
                        </Form.Field>
                        <Form.Field required>
                            <label>Dailypercentage</label>
                            <input
                                type="text"
                                name="dailypercentage"
                                ref={register2({
                                    required: true,
                                })}
                            />
                            {errors2.quantity && errors2.dailypercentage.type === "required" && (
                                <p>Dailypercentage is required</p>
                            )}
                        </Form.Field>
                        <Button type="submit" icon="check" content="Save" />
                    </Form>
                </Modal.Content>
            </Modal>
        </ModalFormLayout>
    );
};

export default CartridgeTypeEditForm;