import axios from "../../axios";
import types from "./types";

const requestIngredients = () => {
  return {
    type: types.INGREDIENTS_REQUEST,
  };
};

const failureIngredients = (err) => {
  return {
    type: types.INGREDIENTS_FAILURE,
    payload: err,
  };
};

const successIngredients = (ingredients) => {
  return {
    type: types.INGREDIENTS_SUCCESS,
    payload: ingredients,
  };
};

const clearErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};

const clearSuccess = () => {
  return {
    type: types.CLEAR_SUCCESS,
  };
};

const requestIngredientNew = () => {
  return {
    type: types.INGREDIENT_NEW_REQUEST,
  };
};

const failureIngredientNew = () => {
  return {
    type: types.INGREDIENT_NEW_FAILURE,
  };
};

const successtIngredientNew = () => {
  return {
    type: types.INGREDIENT_NEW_SUCCESS,
  };
};

const ingredientEdited = (id) => {
  return {
    type: types.INGREDIENTS_EDIT,
    payload: id,
  };
};

const successtIngredientDelete = () => {
  return {
    type: types.INGREDIENT_DELETE_SUCCESS,
  };
};

export const setEditedIngredient = (data) => (dispatch) => {
  dispatch(ingredientEdited(data))
}

export const fetchIngredients = () => async (dispatch) => {
  dispatch(requestIngredients());
  try {
    const { data } = await axios.get("/ingredients");

    dispatch(successIngredients(data.data));
  } catch (err) {
    dispatch(failureIngredients(err.message));
    dispatch(clearErrors());
  }
};

export const newIngredient = (input) => async (dispatch) => {
  dispatch(requestIngredientNew());
  try {
    await axios.post("/ingredients", { ...input });

    dispatch(successtIngredientNew());
    dispatch(clearSuccess());
    dispatch(fetchIngredients());
  } catch (err) {
    dispatch(failureIngredientNew(err.message));
    dispatch(clearErrors());
  }
};

export const editIngredient = (input) => async (dispatch) => {
  dispatch(requestIngredientNew());
  try {
    await axios.post("/ingredients/edit", { ...input });

    dispatch(successtIngredientNew());
    dispatch(clearSuccess());
    dispatch(fetchIngredients());
  } catch (err) {
    dispatch(failureIngredientNew(err.message));
    dispatch(clearErrors());
  }
};

export const deleteIngredient = (input) => async (dispatch) => {
  dispatch(requestIngredientNew());
  try {
    await axios.delete(`/ingredients/delete/${input}`);
    dispatch(successtIngredientDelete());
    dispatch(clearSuccess());
    dispatch(fetchIngredients());
  } catch (err) {
    dispatch(failureIngredientNew(err.message));
    dispatch(clearErrors());
  }
};
