import {v4 as uuid } from 'uuid';
import React, { useEffect, useState } from "react";
import { Form, Button, Dropdown, TextArea, Loader, Dimmer } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import ModalFormLayout from "../Layouts/ModalFormLayout";



const SecondaryDrink = ({ onSaveData }) => {
    const { cartridgeType } = useSelector((state) => state);
    const { drinks } = useSelector((state) => state);
    const [imageLoading, setImageLoading] = useState(false);
    const [errors, setErrors] = useState({

    });
    const dispatch = useDispatch();

    const [state, setState] = useState({
        backgroundColor: "linear-gradient(270deg, #DBC12E 0%, #ED7403 100%)",
        descriptionUrl: null,
        descriptionsImage: null,
        image: null,
        imageUrl: null,
        paragraph1: null,
        paragraph2: null,
    })

    useEffect(() => {
        setState((prevState) => {
            return {
                ...drinks.editingDrink,
                backgroundColor: drinks.editingDrink?.backgroundColor || null,
                descriptionUrl: drinks.editingDrink?.descriptionUrl || null,
                descriptionsImage: drinks.editingDrink?.descriptionsImage || null,
                image: drinks.editingDrink?.image || null,
                imageUrl: drinks.editingDrink?.imageUrl || null,
                paragraph1: drinks.editingDrink?.paragraph1 || null,
                paragraph2: drinks.editingDrink?.paragraph2 || null,
            }
        })
    }, []);

    const { loading, error } = cartridgeType;


    const uploadImage = (type, image) => {
        setImageLoading(true)
        const data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "ngeli_preset")
        data.append("cloud_name", "greglimo")
        fetch(" https://api.cloudinary.com/v1_1/greglimo/image/upload", {
            method: "post",
            body: data
        })
            .then(resp => resp.json())
            .then((data, err) => {
                setImageLoading(false)
                if (type === "descriptionsImage") {
                    setState((prev) => {
                        return {
                            ...prev,
                            descriptionUrl: data.url,
                            descriptionsImage: `h2yo_drink_${uuid()}_description`
                        }
                    })
                } else {
                    setState((prev) => {
                        return {
                            ...prev,
                            imageUrl: data.url,
                            image:`'h2yo_drink_${uuid()}_btn`
                        }
                    })
                }
            })
            .catch(err => console.log(err))
    }

    const onChangeColor = (e) => {
        setState((prev) => {
            return {
                ...prev,
                backgroundColor: e.target.value
            }
        })
    }
    const onChangeParagraph1 = (e) => {
        setState((prev) => {
            return {
                ...prev,
                paragraph1: e.target.value
            }
        })
    }
    const onChangeParagraph2 = (e) => {
        setState((prev) => {
            return {
                ...prev,
                paragraph2: e.target.value
            }
        })
    }

    const onSubmitdata = async (data) => {
        let isvalid = true
        Object.keys(data).forEach(function (key) {
            
            if (key !== 'status' && !data[key]) {
                isvalid = false
                setErrors((prev) => {
                    return {
                        ...prev,
                        [key]: {
                            type: 'required'
                        }
                    }
                })
            }
        });

        if (isvalid === false) {
            return false
        } else {
            onSaveData(state)
            return true
        }

    }


    return (
        <ModalFormLayout
            title="Drink Secondary Data"
        >
            {imageLoading && (
                <Dimmer active>
                    <Loader size='medium'>Image Uploading</Loader>
                </Dimmer>
            )}
            <Form
                noValidate
                size="large"
                loading={loading}
                onSubmit={() => onSubmitdata(state)}
            >
                <Form.Field required>
                    <div style={{ ...StyleSheet.contentWrap }}>
                        <label style={{ ...StyleSheet.label }}>Paragraph 1</label>
                        {errors.paragraph1 && errors.paragraph1.type === "required" && (
                            <p style={{ ...StyleSheet.errorTag }}>Paragraph1 required</p>
                        )}
                    </div>
                    <TextArea maxLength={200} value={state.paragraph1} onChange={(e) => onChangeParagraph1(e)} placeholder='21 words max' style={{ maxHeight: 70 }} />
                </Form.Field>
                <Form.Field required>
                    <div style={{ ...StyleSheet.contentWrap }}>
                        <label style={{ ...StyleSheet.label }}>Paragraph 1</label>
                        {errors.paragraph2 && errors.paragraph2.type === "required" && (
                            <p style={{ ...StyleSheet.errorTag }}>Paragraph2 required</p>
                        )}
                    </div>
                    <TextArea maxLength={200} value={state.paragraph2} onChange={(e) => onChangeParagraph2(e)} placeholder='21 words max' style={{ maxHeight: 70 }} />
                </Form.Field>
                <Form.Field required>
                    <div style={{ ...StyleSheet.contentWrap }}>
                        <label style={{ ...StyleSheet.label }}>Color gradient (Format is strict)</label>
                        {errors.backgroundColor && errors.backgroundColor.type === "required" && (
                            <p style={{ ...StyleSheet.errorTag }}>BackgroundColor required</p>
                        )}
                    </div>
                    <TextArea value={state.backgroundColor} onChange={(e) => onChangeColor(e)} placeholder='linear-gradient(90deg, #ED7474 0%, #ECC7A5 100%)' style={{ maxHeight: 50 }} />
                </Form.Field>
                <div style={{ ...StyleSheet.imageSection }}>
                    <div style={{ ...StyleSheet.imageInputs,backgroundImage: `url(${state.descriptionUrl})` }}>
                        <div style={{ ...StyleSheet.contentWrap }}>
                            <label style={{ ...StyleSheet.label }}>Description Image:</label>
                            {errors.descriptionUrl && errors.descriptionUrl.type === "required" && (
                                <p style={{ ...StyleSheet.errorTag }}>Description image required</p>
                            )}
                        </div>
                        <input style={{ ...StyleSheet.input }} placeholder="Choose Image" type="file" multiple accept="image/*" onChange={(e) => {
                            uploadImage("descriptionsImage", e.target.files[0])
                        }} />
                    </div>
                    <div style={{ ...StyleSheet.imageInputs, backgroundImage: `url(${state.imageUrl})` }}>
                        <div style={{ ...StyleSheet.contentWrap }}>
                            <label style={{ ...StyleSheet.label }}>Button Image:</label>
                            {errors.imageUrl && errors.imageUrl.type === "required" && (
                                <p style={{ ...StyleSheet.errorTag }}>Button image required</p>
                            )}
                        </div>
                        <input style={{ ...StyleSheet.input }} placeholder="Choose Image" type="file" accept="image/*" onChange={(e) => {
                            uploadImage("image", e.target.files[0])
                        }} />
                    </div>
                </div>
                <Button primary name="submit" type="submit" fluid>
                    Next
                </Button>
            </Form>

        </ModalFormLayout>
    );
};

export default SecondaryDrink;

const StyleSheet = {
    imageInputs: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'  
    },
    errorTag: {
        color: 'orangered',
        fontSize: '12px'
    },
    label: {
        marginRight: '8px'
    },
    contentWrap: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background:'transparent'
    },
    input: {
        width: "300px",
        height: '50px',
        background:'transparent'
    },
    imageSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    labelWrapper: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        marginBottom: '30px',
    },
    nameLabel: {
        fontSize: '18px',
        marginRight: '10px',
        marginBottom: '10px'
    },
    buttonsWrapper: {
        display: "flex",
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}
