import types from "./types";

const initialState = {
  isFormModalOpen: false,
  form: null,
};

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_FORM_MODAL:
      return {
        ...state,
        isFormModalOpen: true,
        form: action.payload,
      };
    case types.CLOSE_FORM_MODAL:
      return {
        ...state,
        isFormModalOpen: false,
        form: null,
      };

    default:
      return state;
  }
};

export default modalsReducer;
