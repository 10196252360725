const types = {
    ALTERNATIVES_REQUEST: "ALTERNATIVES_REQUEST",
    ALTERNATIVES_FAILURE: "ALTERNATIVES_FAILURE",
    ALTERNATIVES_SUCCESS: "ALTERNATIVES_SUCCESS",
  
    ALTERNATIVES_NEW_REQUEST: "ALTERNATIVES_NEW_REQUEST",
    ALTERNATIVES_NEW_SUCCESS: "ALTERNATIVES_NEW_SUCCESS",
    ALTERNATIVES_NEW_FAILURE: "ALTERNATIVES_NEW_FAILURE",
  
    CLEAR_ERRORS: "CLEAR_ERRORS",
    CLEAR_SUCCESS: "CLEAR_SUCCESS",
  
    CARTRIDGE_ALTTERNATIVE_EDIT: "CARTRIDGE_ALTTERNATIVE_EDIT",
  
    ALTERNATIVES_DELETE_SUCCESS: "ALTERNATIVES_DELETE_SUCCESS"
};

export default types;