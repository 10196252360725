import axios from "../../axios";
import types from "./types";

const requestCartridgeTypes = () => {
  return {
    type: types.CARTRIDGE_TYPE_REQUEST,
  };
};

const failureCartridgeTypes = (err) => {
  return {
    type: types.CARTRIDGE_TYPE_FAILURE,
    payload: err,
  };
};

const successCartridgeTypes = (cartridgeTypes) => {
  return {
    type: types.CARTRIDGE_TYPE_SUCCESS,
    payload: cartridgeTypes,
  };
};

const successCartridgev2Types = (cartridgeTypes) => {
  return {
    type: types.CARTRIDGES_V2_SUCCESS,
    payload: cartridgeTypes,
  };
};

const clearErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};

const clearSuccess = () => {
  return {
    type: types.CLEAR_SUCCESS,
  };
};

const requestCartridgeNew = () => {
  return {
    type: types.CARTRIDGE_TYPE_NEW_REQUEST,
  };
};

const failureCartridgeNew = () => {
  return {
    type: types.CARTRIDGE_TYPE_NEW_FAILURE,
  };
};

const successtCartridgeNew = () => {
  return {
    type: types.CARTRIDGE_TYPE_NEW_SUCCESS,
  };
};

const successtCartridgeDelete = () => {
  return {
    type: types.CARTRIDGE_TYPE_DELETE_SUCCESS,
  };
};

const cartridgeTypeEdited = (id) => {
  return {
    type: types.CARTRIDGE_TYPE_EDIT,
    payload: id,
  };
};

export const setEditedCartridgeType = (data) => (dispatch) => {
  dispatch(cartridgeTypeEdited(data))
}

export const updateCartridge = (input) => async (dispatch) => {
  dispatch(requestCartridgeTypes());
  try {
    await axios.post("/cartridge_type/edit", { ...input });
    console.log("HHHHHHHHHHHHHHHHHHHH", input)
    dispatch(successCartridgeTypes());
    dispatch(clearSuccess());
    dispatch(fetchCartridgeTypes());
  } catch (err) {
    dispatch(failureCartridgeTypes(err.message));
    dispatch(clearErrors());
  }
};

export const fetchCartridgeTypes = () => async (dispatch) => {
  dispatch(requestCartridgeTypes());
  try {
    const { data } = await axios.get("/cartridge_type");

    dispatch(successCartridgeTypes(data.data));
    console.log(data.data)
  } catch (err) {
    dispatch(failureCartridgeTypes(err.message));
    dispatch(clearErrors());
  }
};

export const fetchCartridgeTypesV2 = () => async (dispatch) => {
  // dispatch(requestCartridgeTypes());
  try {
    const { data } = await axios.get("/cartridge_type/cartridgesv2");

    dispatch(successCartridgev2Types(data));
  } catch (err) {
    dispatch(failureCartridgeTypes(err.message));
    dispatch(clearErrors());
  }
};

export const updateCartridgeTypesV2 = (body, data) => async (dispatch) => {
  // dispatch(requestCartridgeTypes());
  try {
    const { data2 } = await axios.post("/cartridge_type/update_cartridgesv2", { body,data });

    // dispatch(successCrtv2Update(data));
  } catch (err) {
    dispatch(failureCartridgeTypes(err.message));
    dispatch(clearErrors());
  }
};

export const newCartridge = (input) => async (dispatch) => {
  dispatch(requestCartridgeNew());
  try {
    await axios.post("/cartridge_type", { ...input });

    dispatch(successtCartridgeNew());
    dispatch(clearSuccess());
    dispatch(fetchCartridgeTypes());
  } catch (err) {
    dispatch(failureCartridgeNew(err.message));
    dispatch(clearErrors());
  }
};

export const deleteCartridgeType = (id) => async (dispatch) => {
  try {
    await axios.get(`/cartridge_type/delete/${id}`);

    dispatch(successtCartridgeDelete())
    dispatch(clearSuccess());
    dispatch(fetchCartridgeTypes());
  } catch (error) {
    dispatch(failureCartridgeNew("Failed to delete."));
    dispatch(clearErrors());
  }
}
