import axios from "../../axios";
import types from "./types";

const successAlternatives = (alternatives) => {
  return {
    type: types.ALTERNATIVES_SUCCESS,
    payload: alternatives,
  };
};

export const fetchAlternatives = () => async (dispatch) => {
  //   dispatch(requestCartridgeTypes());
  try {
    const { data } = await axios.get("/cartridgeAlternatives");

    dispatch(successAlternatives(data.data));
  } catch (err) {
    // dispatch(failureCartridgeTypes(err.message));
    // dispatch(clearErrors());
  }
};

const cartridgeAlternativeEdited = (id) => {
  return {
    type: types.CARTRIDGE_ALTTERNATIVE_EDIT,
    payload: id,
  };
};

export const setEditedCartridgeAlternative = (data) => (dispatch) => {
  dispatch(cartridgeAlternativeEdited(data));
};

export const addAlternative = (alternative) => async (dispatch) => {
  try {
    if (!alternative.cartridgeTypeId || !alternative.typesToSubstitute.length)
      return;

    const { data } = await axios.post("/cartridgeAlternatives", alternative);
    dispatch(fetchAlternatives());
  } catch (error) {
    console.log(error);
  }
};

export const deleteCartridgeAlternative = (id) => async (dispatch) => {
  try {
    const { data } = await axios.post("/cartridgeAlternatives/delete", { id });
    dispatch(fetchAlternatives());
  } catch (error) {
    console.log(error);
  }
};
