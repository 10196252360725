import React, { useEffect, useState } from "react";
import { Header, Table } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Popup, Modal, List } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchLanguages, setEditedLanguage } from "../../redux/i18n/i18n.actions";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import { fetchCartridgeTypes,fetchCartridgeTypesV2,updateCartridgeTypesV2, deleteCartridgeType, setEditedCartridgeType } from "../../redux/cartridgeType/cartridgeType.actions";
import { openFormModal } from "../../redux/modals/modals.actions";

// Form Constants
import form from "../../redux/modals/constants";

import { deleteLanguage } from "../../redux/i18n/i18n.actions";

const InternationalizationComponent = () => {
  const dispatch = useDispatch();
  const { i18n: { loading, languages, error, success }} = useSelector((state) => state);

  const onDelete = (id) => {
    dispatch(deleteLanguage(id))
  }

  useEffect(() => {
    dispatch(fetchLanguages());
  }, []);

  if (error) {
    toast.error(error);
  }

  const onEdit = (id) => {
    dispatch(setEditedLanguage(id))
    dispatch(openFormModal(form.EDIT_LANGUAGE))
  }

  if (success) {
    if (success === "Ingredient Deleted Successfully"){
      toast.success(success);
    }
  }

  const renderRow = (lang) => {
    return (
      <Table.Row key={lang.id}>
        <Table.Cell>{lang.id}</Table.Cell>
        <Table.Cell>{lang.translations.language}</Table.Cell>
        <Table.Cell>
          <ActionButtonGroup id={lang.id} onEdit={onEdit} onDelete={onDelete}/>
        </Table.Cell>
      </Table.Row>
    );
  }

  const renderRows = () => {
    
    return Object.keys(languages).length ? Object.keys(languages).map(key => {
      return renderRow(languages[key])
    }) : []
  }

  return (
    <DashboardContentLayout
      title="Internationalization"
      openAddModal={() => dispatch(openFormModal(form.NEW_LANGUAGE))}
      openEditStructureModal={() => dispatch(openFormModal(form.EDIT_LANG_STRUCTURE))}
    >
      <Table basic="very" celled collapsing size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Language</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderRows()}
        </Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default InternationalizationComponent;
