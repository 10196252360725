import React, { useEffect, useState } from "react";
import { Header, Table, Input, Form, TextArea, Button, Accordion, Icon } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import { addInstruction, fetchInstructions, deleteInstructions } from "../../redux/instructions/instructions.actions";
import { openFormModal } from "../../redux/modals/modals.actions";

// Form Constants
import form from "../../redux/modals/constants";

const InstructionsComponent = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({ title: '', message: '' })
    const [instructionsTemp, setInstructions] = useState([])

    const { cartridgeType, ingredients, instructions } = useSelector((state) => state);

    const { loading, error } = instructions;

    const onDeleteCrt = (id) => {

    }

    const onEdit = (id) => {

    }

    useEffect(() => {
        dispatch(fetchInstructions());
    }, [dispatch]);

    if (error) {
        toast.error(error);
    }
    const [state, setState] = useState({ activeIndex: 0 })


    useEffect(() => {
        if (instructions.instructions) {
            setInstructions(instructions.instructions)
        }
    }, [instructions])

    const handleClick = (index) => {
        const newIndex = state.activeIndex === index ? -1 : index
        setState({ activeIndex: newIndex })
    }
    const handleDelete = (instruction) => {
        dispatch(deleteInstructions(instruction))
    }

    console.log(instructions)

    return (
        <DashboardContentLayout
            title="Cartridge Type Instructions"
            loading={loading || ingredients.loading}
        // openAddModal={() => dispatch(openFormModal(form.NEW_CARTRIDGE_TYPE))}
        >
            <Accordion>
                {
                    instructionsTemp.length && instructionsTemp.map((instruction, index) => {
                        return (
                            < div style={{ marginBottom: "12px" }}>
                                <Accordion.Title
                                    active={state.activeIndex === index}
                                    index={index}
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                        fontSize: "16px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}>
                                            <Icon
                                                onClick={() => handleClick(index)}
                                                name='dropdown' />
                                            <p style={{ justifySelf: "flex-start" }}>{instruction.title}</p>
                                        </div>
                                        <div>
                                            <Icon
                                                name="remove"
                                                onClick={() => handleDelete(instruction)}
                                            />
                                        </div>
                                    </div>
                                </Accordion.Title>
                                <Accordion.Content active={state.activeIndex === index}>
                                    <div>
                                        <p>{instruction.message}</p>
                                    </div>
                                </Accordion.Content>
                            </div>
                        )
                    })
                }
            </Accordion>
            <Form
                onSubmit={() => {
                    if (!form.message || !form.title) {
                        console.log(form)
                        return
                    }
                    setInstructions((prevState) => {
                        return [...prevState, form]
                    })
                    dispatch(addInstruction(form))
                    setForm({ title: '', message: '' })
                }}
            >
                <Form.Field required>
                    <label>Title</label>
                    <input
                        type="text"
                        name="title"
                        value={form.title}
                        onChange={(e) => setForm((prevState) => {
                            return {
                                ...prevState,
                                title: e.target.value
                            }
                        })}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Message</label>
                    <TextArea
                        type="text"
                        name="message"
                        value={form.message}
                        onChange={(e) => setForm((prevState) => {
                            return {
                                ...prevState,
                                message: e.target.value
                            }
                        })}
                    />
                </Form.Field>
                <Button color={form.message && form.title ? "green" : "grey"} type='submit'>Submit</Button>
            </Form>
        </DashboardContentLayout>
    );
};

export default InstructionsComponent;
