const types = {
  CARTRIDGE_TYPE_REQUEST: "CARTRIDGE_TYPE_REQUEST",
  CARTRIDGE_TYPE_FAILURE: "CARTRIDGE_TYPE_FAILURE",
  CARTRIDGE_TYPE_SUCCESS: "CARTRIDGE_TYPE_SUCCESS",
  CARTRIDGES_V2_SUCCESS: "CARTRIDGES_V2_SUCCESS",

  CARTRIDGE_TYPE_NEW_REQUEST: "CARTRIDGE_TYPE_NEW_REQUEST",
  CARTRIDGE_TYPE_NEW_SUCCESS: "CARTRIDGE_TYPE_NEW_SUCCESS",
  CARTRIDGE_TYPE_NEW_FAILURE: "CARTRIDGE_TYPE_NEW_FAILURE",
  CARTRIDGE_TYPE_DELETE_SUCCESS: "CARTRIDGE_TYPE_DELETE_SUCCESS",

  CARTRIDGE_TYPE_EDIT: "CARTRIDGE_TYPE_EDIT",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",
};

export default types;
