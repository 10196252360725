import axios from "../../axios";
import authTypes from "./auth.types";

import { JWT_TITLE } from "../../utils/constants";

const requestAuth = () => {
  return {
    type: authTypes.AUTH_REQUEST,
  };
};

const failureAuth = (err) => {
  return {
    type: authTypes.AUTH_FAILURE,
    payload: err,
  };
};

const requestSignOut = () => {
  return {
    type: authTypes.SIGN_OUT_REQUEST,
  };
};

const successSignOut = () => {
  return {
    type: authTypes.SIGN_OUT_SUCCESS,
  };
};

const errorSignOut = () => {
  return {
    type: authTypes.SIGN_OUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: authTypes.VERIFY_REQUEST,
  };
};

const verifyComplete = (user) => {
  return {
    type: authTypes.VERIFY_COMPLETE,
    payload: user,
  };
};

const clearErrors = () => {
  return {
    type: authTypes.CLEAR_ERRORS,
  };
};

export const verifyAuth = () => async (dispatch) => {
  try {
    dispatch(verifyRequest());
    const { data } = await axios.get("/auth/verify", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(JWT_TITLE) || null}`,
      },
    });

    dispatch(verifyComplete(data.data));
  } catch (err) {
    console.log("Err is: ", err);
    dispatch(signOutUser());
  }
};

export const signInUser = (email, password) => async (dispatch) => {
  dispatch(requestAuth());
  try {
    const { data } = await axios.post("/auth/login", {
      email,
      password,
    });

    localStorage.setItem(JWT_TITLE, data.data);
    dispatch(verifyAuth());
  } catch (err) {
    // handle the error
    dispatch(failureAuth(err.message));
    dispatch(clearErrors());
  }
};

export const signOutUser = () => async (dispatch) => {
  dispatch(requestSignOut());
  try {
    dispatch(requestSignOut());
    localStorage.removeItem(JWT_TITLE);
    dispatch(successSignOut());
  } catch (err) {
    dispatch(errorSignOut());
    dispatch(clearErrors());
  }
};
