import authTypes from "./auth.types";

const initialState = {
  loading: true,
  error: null,
  isAuthenticated: true,
  user: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case authTypes.AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: false,
        user: {},
      };
    case authTypes.AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Wrong email and or password",
        isAuthenticated: false,
        user: {},
      };
    case authTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: false,
        user: {},
      };
    case authTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: false,
        user: {},
      };
    case authTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Error signing out",
        isAuthenticated: false,
        user: {},
      };

    case authTypes.VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        isAuthenticated: false,
        user: {},
      };
    case authTypes.VERIFY_COMPLETE:
      return {
        ...state,
        loading: false,
        error: null,
        isAuthenticated: true,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
