import React from "react";
import { Menu } from "semantic-ui-react";

// Components
import UserDropDown from "./UserDropDown";

const TopNav = () => {
  return (
    <Menu secondary size="huge">
      <Menu.Item name="H2Y0 Cloud" />

      <Menu.Menu position="right">
        <Menu.Item>
          <UserDropDown />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default TopNav;
