const types = {
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_FAILURE: "AUTH_FAILURE",

  SIGN_OUT_REQUEST: "SIGN_OUT_REQUEST",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",

  VERIFY_REQUEST: "VERIFY_REQUEST",
  VERIFY_COMPLETE: "VERIFY_COMPLETE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default types;
