import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import ingredientsReducer from "./ingredients/ingredients.reducer";
import modalsReducer from "./modals/modals.reducer";
import cartridgeTypeReducer from "./cartridgeType/cartridgeType.reducer";
import deviceCartridgeReducer from "./deviceCartridge/deviceCartridge.reducer";
import drinksReducer from "./drinks/drinks.reducer";
import i18nReducer from "./i18n/i18n.reducer";
import instructionsReducer from "./instructions/instructions.reducer";
import alternativesReducer from "./alternatives/alternatives.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  ingredients: ingredientsReducer,
  modals: modalsReducer,
  cartridgeType: cartridgeTypeReducer,
  deviceCartridge: deviceCartridgeReducer,
  drinks: drinksReducer,
  i18n: i18nReducer,
  instructions: instructionsReducer,
  alternatives: alternativesReducer,
});

export default rootReducer;
