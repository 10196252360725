import types from "./types";

const initialState = {
  loading: false,
  error: null,
  usedDeviceCartridges: [],
};

const deviceCartridgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    case types.DEVICE_CARTRIDGE_USED_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        usedDeviceCartridges: [],
      };
    case types.DEVICE_CARTRIDGE_USED_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not load used device cartridges",
        usedDeviceCartridges: [],
      };
    case types.DEVICE_CARTRIDGE_USED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        usedDeviceCartridges: action.payload,
      };
    default:
      return state;
  }
};

export default deviceCartridgeReducer;
