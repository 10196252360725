import React from "react";
import CartridgeTypeComponent from "../Components/CartridgeType/index";

import DashboardLayout from "../Components/Layouts/DashboardLayout";

const CartridgesPage = () => {
  return (
    <DashboardLayout title="Cartridge Type | H2Y0">
      <CartridgeTypeComponent />
    </DashboardLayout>
  );
};

export default CartridgesPage;
