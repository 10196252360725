const types = {
  DEVICE_CARTRIDGE_USED_REQUEST: "DEVICE_CARTRIDGE_USED_REQUEST",
  DEVICE_CARTRIDGE_USED_FAILURE: "DEVICE_CARTRIDGE_USED_FAILURE",
  DEVICE_CARTRIDGE_USED_SUCCESS: "DEVICE_CARTRIDGE_USED_SUCCESS",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",
};

export default types;
