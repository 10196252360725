import React, { useEffect } from "react";
import { Header, Table, Button, Modal } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import {
  getDrinks,
  toggleDrinkModal,
  deleteDrink,
  editDrink,
  editDrinkModal,
} from "../../redux/drinks/drinks.actions";

// Form Constants
import ComposeDrink from "./composeDrink";
import EditDrink from "./editDrink";

const DrinksComponent = () => {
  const dispatch = useDispatch();
  const { drinks } = useSelector((state) => state);

  const { loading, error } = drinks;

  const onDeleteDrink = (id) => {
    dispatch(deleteDrink(id));
  };

  const onEdit = (drink) => {
    dispatch(editDrinkModal(drink));
  };
  const editStatus = (value) => {
    dispatch(editDrink(value._id, { status: !value.status }));
  };

  useEffect(() => {
    dispatch(getDrinks());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  let renderCartridgeType;

  if (drinks.drinkList && drinks.drinkList.length) {
    renderCartridgeType = drinks.drinkList.map((value) => {
      let ingredientsContained;

      if (value.ingredients && value.ingredients.length) {
        ingredientsContained = value.ingredients.map((ingredient) => {
          return `${ingredient.name}, `;
        });
      } else {
        ingredientsContained = "None";
      }

      return (
        <Table.Row key={value._id}>
          <Table.Cell>
            <Header as="h4" image>
              <Header.Content>{value.name}</Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>{ingredientsContained}</Table.Cell>
          <Table.Cell>{value.updatedAt}</Table.Cell>
          <Table.Cell>{value.status}</Table.Cell>
          <Table.Cell>
            <Button
              toggle
              active={value.status}
              onClick={() => editStatus(value)}
            >
              {value.status ? "Active" : "TEST"}
            </Button>
          </Table.Cell>
          <Table.Cell>
            <ActionButtonGroup
              id={value._id}
              onDelete={onDeleteDrink}
              onEdit={() => onEdit(value)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  } else {
    renderCartridgeType = (
      <Table.Row>
        <Table.Cell>No cartridge types found.</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <DashboardContentLayout
      title="H2YO Drinks"
      loading={loading}
      openAddModal={() => dispatch(toggleDrinkModal())}
    >
      <Modal
        style={{
          width: "90%",
        }}
        size={"large"}
        open={drinks.drinkModal}
        onClose={() => dispatch(toggleDrinkModal())}
      >
        <ComposeDrink />
      </Modal>
      <Modal
        style={{
          width: "90%",
        }}
        size={"large"}
        open={drinks.editModal}
        onClose={() => dispatch(editDrinkModal())}
      >
        <EditDrink />
      </Modal>
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Contents</Table.HeaderCell>
            <Table.HeaderCell>Last Updated</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderCartridgeType}</Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default DrinksComponent;
