import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";

import AppRouter from "./routes/AppRouter";
import ModalLayout from "./Components/Modals/ModalLayout";

// Actions
import { verifyAuth } from "./redux/auth/auth.actions";

// CSS
import "react-toastify/dist/ReactToastify.css";

import { fetchCartridgeTypes } from './redux/cartridgeType/cartridgeType.actions'
import { fetchLanguages } from "./redux/i18n/i18n.actions";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuth());
    dispatch(fetchCartridgeTypes());
    dispatch(fetchLanguages());
  }, [dispatch]);

  return (
    <>
      <AppRouter />
      <ModalLayout />
      <ToastContainer />
    </>
  );
};

export default App;
