import axios from "axios";

import { JWT_TITLE } from "./utils/constants";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(JWT_TITLE) || null}`,
  },
});

export default axiosInstance;
