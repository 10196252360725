import React from "react";

import DashboardLayout from "../Components/Layouts/DashboardLayout";
import Ingredients from "../Components/Ingredients";

const IngredientsPage = () => {
  return (
    <DashboardLayout title="Ingredients | H2Y0">
      <Ingredients />
    </DashboardLayout>
  );
};

export default IngredientsPage;
