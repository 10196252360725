import React from "react";

import IngredientEditForm from "./IngredientEditForm";
import IngredientNewForm from "./IngredientNewForm";
import CartridgeTypeNewForm from "./CartridgeTypeNewForm";
import CartridgeTypeEditForm from "./CartridgeTypeEditForm";
import LanguageForm from "./LanguageForm"
import LanguageStructureForm from "./LanguageStructureForm"
import CartridgeAlternativeNewForm from "./CartridgeAlternativeNewForm";
// Form constants
import form from "../../redux/modals/constants";

const FormToRender = (props) => {
  switch (props.value) {
    case form.NEW_INGREDIENT:
      return <IngredientNewForm />;
    case form.EDIT_INGREDIENT:
      return <IngredientEditForm />;
    case form.NEW_CARTRIDGE_TYPE:
      return <CartridgeTypeNewForm />;
    case form.EDIT_CARTRIDGE_TYPE:
      return <CartridgeTypeEditForm />;
    case form.NEW_CARTRIDGE_ALTERNATIVE:
      return <CartridgeAlternativeNewForm />;
    case form.NEW_LANGUAGE:
      return <LanguageForm isNew/>;
    case form.EDIT_LANGUAGE:
      return <LanguageForm />;
    case form.EDIT_LANG_STRUCTURE:
      return <LanguageStructureForm />
    default:
      return <IngredientNewForm />;
  }
};

export default FormToRender;
