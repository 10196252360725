import axios from "../../axios";
import types from "./types";

const successtdrinks = () => {
  return {
    type: types.DRINKS_LOADING_SUCCESS,
  };
};

export const requestdrinks = () => {
  return {
    type: types.DRINKS_LOADING,
  };
};
const fetchdrinks = (drinks) => {
  return {
    type: types.GET_ALL_DRINKS,
    payload: drinks,
  };
};
const failureDrinks = (message) => {
  return {
    type: types.DRINKS_LOADING_ERROR,
    payload: message,
  };
};

const clearErrors = () => {
  return {
    type: types.CLEAR_ERRORS,
  };
};

export const toggleDrinkModal = () => {
  return {
    type: types.TOGGLE_DRINK_MODAL,
  };
};

export const editDrinkModal = (value) => {
  return {
    type: types.EDIT_DRINK_MODAL,
    payload: value,
  };
};

export const secondaryDrinkModal = (value) => {
  return {
    type: types.SECONDARY_DRINK_MODAL,
    payload: value,
  };
};

export const getDrinks = () => async (dispatch) => {
  dispatch(requestdrinks());
  try {
    const { data } = await axios.get("/drinks/alldrinks");

    dispatch(successtdrinks());
    dispatch(fetchdrinks(data));
  } catch (err) {
    dispatch(failureDrinks(err.message));
    dispatch(clearErrors());
  }
};

export const deleteDrink = (id) => async (dispatch) => {
  try {
    const { data } = await axios.post("/drinks/delete", { id });
    dispatch(getDrinks());
  } catch (err) {
    dispatch(clearErrors());
  }
};

export const editDrink = (id, payload) => async (dispatch) => {
  try {
    const { data } = await axios.post("/drinks/edit", { id, data: payload });
    dispatch(getDrinks());
  } catch (err) {
    dispatch(clearErrors());
  }
};
