import React, { useEffect, useState } from "react";
import { Table, Modal } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import ComposeDrinkLayout from "../Layouts/ComposeDrinkLayout";

// Actions
import { openFormModal } from "../../redux/modals/modals.actions";
import { getDrinks, editDrinkModal, requestdrinks, secondaryDrinkModal  } from "../../redux/drinks/drinks.actions";

// Form Constants
import form from "../../redux/modals/constants";

import EditedDrinkIngredient from "./editedDrinkIngredient";
import axiosInstance from "../../axios";
import SecondaryDrink from "./secondaryDrink";

const EditDrinkComponent = () => {
    const { cartridgeType } = useSelector((state) => state);
    const { drinks } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        ...drinks.editingDrink
    })
    const [Errors, setErrors] = useState({
        noName: false
    })
    const [mode, setMode] = useState('ml')
    const [hw, setHw] = useState('V3')

    const { loading, error } = cartridgeType;

    useEffect(() => {
        setState((prevState) => {
            return {
                ...drinks.editingDrink,
            }
        })
    }, []);

    const orderDrinkItemsByIndex = (data, currentIndex) => {
        // Increment the index of the item being moved by 1 and put it in the new index
        console.log(data, currentIndex)
        let ingredients = state.ingredients
        const filtered = ingredients.filter((item) => {
            return item._id !== data._id
        }
        )
        filtered.splice(currentIndex, 0, data)
        setState((prevState) => {
            return {
                ...prevState,
                ingredients: filtered
            }
        }
        )

    }

    const setDrinkItems = (data) => {
        delete data._rev
        let ingredients = state.ingredients
        const filtered = ingredients.filter((item) => {
            return item._id !== data._id
        })
        filtered.push(data)
        setState((prevState) => {
            return {
                ...prevState,
                ingredients: filtered
            }
        })
    }
    const removeFromDrink = (data) => {
        let ingredients = state.ingredients
        const filtered = ingredients.filter((item) => {
            return item._id !== data._id
        })
        setState((prevState) => {
            return {
                ...prevState,
                ingredients: filtered
            }
        })
    }

    if (error) {
        toast.error(error);
    }

    let renderCartridgeType;

    if (drinks.editingDrink) {
        var ids = new Set(drinks.editingDrink.ingredients.map(d => d._id));
        const filtered = [...drinks.editingDrink.ingredients, ...cartridgeType.cartridgeTypes.filter(d => !ids.has(d._id))];
        renderCartridgeType = filtered.map((value, index) => {

            return (
                <EditedDrinkIngredient orderDrinkItemsByIndex={orderDrinkItemsByIndex} index={index} mode={mode} key={value._id} value={value} setDrinkItems={setDrinkItems} removeFromDrink={removeFromDrink} hw={hw}/>
            );
        });
    } else {
        renderCartridgeType = (
            <Table.Row>
                <Table.Cell>No cartridge types found.</Table.Cell>
            </Table.Row>
        );
    }

    const onChangeName = (name) => {
        setState((prevState) => {
            return {
                ...prevState,
                name
            }
        })
    }
    const onChangeMode = (e, mode) => {
        setMode(mode.value)
    }
    const onChangeHwVersion = (e, mode) => {
        setHw(mode.value)
    }
    const saveDrink = async (name) => {
        if (!state.name) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    noName: true
                }
            })
        } else {
            console.log(state)
            // order state.ingredients by its rank property, if no rank property, put thos with rank first then others
            const ordered = state.ingredients.sort((a, b) => {
                if (a.rank && b.rank) {
                    return a.rank - b.rank
                } else if (a.rank) {
                    return -1
                } else if (b.rank) {
                    return 1
                } else {
                    return 0
                }
            })
            const orderedState = {
                ...state,
                ingredients: ordered
            }


            dispatch(requestdrinks())
            const data = await axiosInstance.post('/drinks/edit', { id: state._id, data: orderedState })
            dispatch(getDrinks());
            dispatch(editDrinkModal())
        }
    }
    const onSaveData = (data) => {
        setState((prevState) => {
          return {
            ...prevState,
            ...data
          }
        })
        dispatch(secondaryDrinkModal())
        console.log(state)
      }
    const toggleStatus = (status) => {
        setState((prevState) => {
            return {
                ...prevState,
                status
            }
        })
        console.log(status)
    }
    const setdescImage = (value) => {
        setState((prevState) => {
          return {
            ...prevState,
            descriptionsImage: value
          }
        })
        console.log(value)
      }
      const setBtnImage = (value) => {
        setState((prevState) => {
          return {
            ...prevState,
            image: value
          }
        })
        console.log(value)
      }
    return (
        <ComposeDrinkLayout
            onChangeHwVersion={onChangeHwVersion}
            onChangeMode={onChangeMode}
            title="Edit Drink"
            loading={loading}
            openAddModal={() => dispatch(openFormModal(form.NEW_DRINK))}
            onChangeName={onChangeName}
            saveDrink={saveDrink}
            setStatus={toggleStatus}
            setBtnImage={setBtnImage}
            setdescImage={setdescImage}
            secondaryDrinkModal={() => dispatch(secondaryDrinkModal())}
        >
                  <Modal
        size={"medium"}
        open={drinks.secondaryModal}
        onClose={() => dispatch(secondaryDrinkModal())}
      >
        <SecondaryDrink onSaveData={onSaveData} />
      </Modal>
            <Table basic="very" celled collapsing size="small" compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Order</Table.HeaderCell>
                        <Table.HeaderCell>In Drink?</Table.HeaderCell>
                        <Table.HeaderCell>Intensity 1</Table.HeaderCell>
                        <Table.HeaderCell>Intensity 2</Table.HeaderCell>
                        <Table.HeaderCell>Intensity 3</Table.HeaderCell>
                        <Table.HeaderCell>Is Boost?</Table.HeaderCell>
                        <Table.HeaderCell>Boost Level1</Table.HeaderCell>
                        <Table.HeaderCell>Boost Level2</Table.HeaderCell>
                        <Table.HeaderCell>Boost Level3</Table.HeaderCell>
                        <Table.HeaderCell>Default boost Level</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{renderCartridgeType}</Table.Body>
            </Table>
        </ComposeDrinkLayout>
    );
};

export default EditDrinkComponent;
