import React from "react";
import { Button, Popup } from "semantic-ui-react";
// const color=""
const ActionButtonGroupV2 = ({ id, onDelete, onEdit, data,color }) => (
  <Button.Group basic size="small">
        <Popup content="Save" trigger={<Button icon={`${color? "save": "edit"} outline ${color? "green" : null}`} onClick={() => onEdit(id, data)}/>} />
    {/* <Popup content="Edit" trigger={<Button icon="edit outline" />} onClick={() => onEdit(id)} /> */}
    <Popup
      content="Delete"
      trigger={<Button icon="delete" onClick={() => console.log(id)} />}
    />
  </Button.Group>
);

export default ActionButtonGroupV2;
