export const units = [
    {
        name: 'Gram',
        value: 'gram'
    },
    {
        name: 'milliliter',
        value: 'milliliter'
    },
    {
        name: 'Milligram',
        value: 'milligram'
    },
    {
        name: 'Microgram',
        value: 'microgram'
    },
    {
        name: 'Nanogram',
        value: 'nanogram'
    },
    {
        name: 'kcal',
        value: 'kcal'
    }
]