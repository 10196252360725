import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Popup, Modal, List, TextArea, Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Layout
import ModalFormLayout from "../Layouts/ModalFormLayout";

// Actions
import { newCartridge } from "../../redux/cartridgeType/cartridgeType.actions";
import { closeFormModal } from "../../redux/modals/modals.actions";
import { fetchIngredients } from "../../redux/ingredients/ingredients.actions";

const multipleCartridgeOptions = [
    { key: 'true', value: true, text: 'Yes' },
    { key: 'false', value: false, text: 'No' },
]

const CartridgeTypeNewForm = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchIngredients());
    }, [dispatch]);

    const { register, handleSubmit, errors } = useForm();

    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
    } = useForm();

    const [cartIngredients, setCartIngredients] = useState([]);

    const [open, setOpen] = useState(false);
    const [allowMultiple, setAllowMultiple] = useState(false);
    const [bagOptions, setBagOptions] = useState("250, 300, 320");

    const { loading, success } = useSelector((state) => state.cartridgeType);
    const ingredients = useSelector((state) => state.ingredients);

    const updateIngredient = (id, whichvalue, newvalue, which2, new2) => {
        let index = cartIngredients.findIndex((x) => x._id === id);

        if (index !== -1) {
            let temporaryarray = cartIngredients.slice();
            temporaryarray[index][whichvalue] = newvalue;
            temporaryarray[index][which2] = new2;
            setCartIngredients(temporaryarray);
        } else {
            //TODO Check how id parsed below is used in server
            setCartIngredients([...cartIngredients, { id, quantity: newvalue, dailypercentage: +new2 }]);
        }
    };

    const removeIngredient = (id) => {
        setCartIngredients(
            cartIngredients.filter((ingredient) => ingredient._id !== id)
        );
    };

    const ingredientSubmit = (data) => {
        console.log(data)
        updateIngredient(data.id, "quantity", data.quantity, 'dailypercentage', data.dailypercentage);
        setOpen(false);
    };

    const onChange = (e, data) => {
        switch (data) {
            case 'bagVolumeOptions':
                setBagOptions(e.target.value);
                break;
            case 'multipleInstallations':
                setAllowMultiple(e);
                break;
            default:
                break;
        }
    }

    const onSubmit = (data) => {
        dispatch(newCartridge({
            ...data,
            _id: random().toString(),
            dose: JSON.parse(`[${data.dose}]`),
            multipleInstallations: allowMultiple,
            bagVolumeOptions: bagOptions.split(',').map(item => +item.trim()).filter(item => !isNaN(item)),
            ingredients: cartIngredients,
            title: data.title || '',
            paragraph: data.paragraph || ''
        }));
    };

    if (success) {
        toast.success(success);
        dispatch(closeFormModal());
    }

    let selectedIngredients;

    if (cartIngredients && cartIngredients.length) {
        selectedIngredients = cartIngredients.map((value) => {
            return (
                <List.Item key={value.id}>
                    <List.Content floated="right">
                        <Popup
                            content="Remove ingredient"
                            trigger={
                                <Button
                                    circular
                                    icon="remove"
                                    onClick={() => removeIngredient(value._id)}
                                />
                            }
                        />
                    </List.Content>
                    <List.Content>
                        <List.Header>Ingredient Id: {value.id}</List.Header>
                        <List.Description>Quantity: {value.quantity}</List.Description>
                        <List.Description>Dailypercentage: {value.dailypercentage}</List.Description>
                    </List.Content>
                </List.Item>
            );
        });
    } else {
        selectedIngredients = "No ingredients selected";
    }
    function random() {
        const ran1 = Math.floor(1000 + (9999 - 1000) * Math.random());
        const ran2 = Math.floor(1000 + (9999 - 1000) * Math.random());

        const res =
            ran1 > ran2
                ? Math.floor(ran2 + (ran1 - ran2) * Math.random())
                : Math.floor(ran1 + (ran2 - ran1) * Math.random());
        return res;
    }
    console.log(ingredients)
    return (
        <ModalFormLayout title="New Cartridge Type">
            <Form
                noValidate
                size="large"
                loading={loading || ingredients.loading}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Form.Field required>
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.name && errors.name.type === "required" && (
                        <p>Cartridge type name is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Tittle</label>
                    <input
                        type="text"
                        name="title"
                        ref={register({
                            required: false,
                        })}
                    />
                    {errors.title && errors.title.type === "required" && (
                        <p>Cartridge tittle is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Paragraph</label>
                    <input
                        type="text"
                        name="paragraph"
                        ref={register({
                            required: false,
                        })}
                    />
                    {errors.paragraph && errors.paragraph.type === "required" && (
                        <p>Cartridge paragraph is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Volume</label>
                    <input
                        type="text"
                        name="volume"
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.volume && errors.volume.type === "required" && (
                        <p>Volume is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Dosing Speed</label>
                    <input
                        type="text"
                        name="dosingSpeed"
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.dosingSpeed && errors.dosingSpeed.type === "required" && (
                        <p>Dosing speed is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Color code (Hexadecimal)</label>
                    <input
                        type="text"
                        name="color"
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.color && errors.color.type === "required" && (
                        <p>Color code is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Dose ( 2000,3000,5000)</label>
                    <input
                        type="text"
                        name="dose"
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.dose && errors.dose.type === "required" && (
                        <p>Dose is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Bag Volume options ( 250,300,320)</label>
                    <input
                        type="text"
                        name="bagVolumeOptions"
                        onChange={(e) => onChange(e, "bagVolumeOptions")}
                        value={bagOptions}
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.dose && errors.dose.type === "required" && (
                        <p>Volume options are required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Density ( Weight per 100ml)</label>
                    <input
                        type="text"
                        name="density"
                        ref={register({
                            required: true,
                        })}
                    />
                    {errors.density && errors.density.type === "required" && (
                        <p>Density is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Allow multiple installations in device</label>
                    <Dropdown 
                        placeholder={'No'} 
                        search 
                        selection 
                        options={multipleCartridgeOptions} 
                        onChange={(e, e2)=> 
                        onChange(e2.value, "multipleInstallations")} 
                        />
                    {errors.density && errors.density.type === "required" && (
                        <p>Density is required</p>
                    )}
                </Form.Field>
                <Form.Field required>
                    <label>Ingredients</label>

                    <List divided verticalAlign="middle">
                        {selectedIngredients}
                    </List>
                    <Popup
                        content="Add new ingredient"
                        trigger={
                            <Button
                                circular
                                name="add"
                                type="button"
                                icon="add"
                                onClick={() => setOpen(true)}
                            />
                        }
                    />
                </Form.Field>

                <Button primary name="submit" type="submit" fluid>
                    Save
                </Button>
            </Form>
            <Modal onClose={() => setOpen(false)} open={open} size="small">
                <Modal.Header>Add Ingredient</Modal.Header>
                <Modal.Content>
                    <Form
                        loading={loading || ingredients.loading}
                        onSubmit={handleSubmit2(ingredientSubmit)}
                    >
                        <Form.Field required>
                            <label>Ingredient</label>
                            <select
                                name="id"
                                ref={register2({
                                    required: true,
                                })}
                            >
                                {ingredients.ingredients.map((value) => {
                                    return (
                                        <option key={value._id} value={value._id}>
                                            {value.name} ({value.unit})
                                        </option>
                                    );
                                })}
                            </select>
                            {errors2.id && errors2.id.type === "required" && (
                                <p>Ingredient is required</p>
                            )}
                        </Form.Field>
                        <Form.Field required>
                            <label>Quantity</label>
                            <input
                                type="text"
                                name="quantity"
                                ref={register2({
                                    required: true,
                                })}
                            />
                            {errors2.quantity && errors2.quantity.type === "required" && (
                                <p>Quantity is required</p>
                            )}
                        </Form.Field>
                        <Form.Field required>
                            <label>Daily Percentage</label>
                            <input
                                type="text"
                                name="dailypercentage"
                                ref={register2({
                                    required: true,
                                })}
                            />
                            {errors2.quantity && errors2.dailypercentage.type === "required" && (
                                <p>Daily Percentage is required</p>
                            )}
                        </Form.Field>
                        <Button type="submit" icon="check" content="Save" />
                    </Form>
                </Modal.Content>
            </Modal>
        </ModalFormLayout>
    );
};

export default CartridgeTypeNewForm;
