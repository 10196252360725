import types from "./types";

const initialState = {
  loading: false,
  error: null,
  success: null,
  cartridgeTypes: [],
  cartridgeTypev2:[],
  editedCartridgeType: null,
};

const cartridgeTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case types.CLEAR_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.CARTRIDGE_TYPE_NEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case types.CARTRIDGE_TYPE_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "New CartridgeTpe Added",
      };
    case types.CARTRIDGE_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "Cartridge Deleted successfully",
      };
    case types.CARTRIDGE_TYPE_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not create new cartridge type",
        success: null,
      };
    case types.CARTRIDGE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        cartridgeTypes: [],
      };
    case types.CARTRIDGE_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not fetch cartridge types, please try again",
        success: null,
        cartridgeTypes: [],
      };
    case types.CARTRIDGE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        cartridgeTypes: action.payload,
      };
    case types.CARTRIDGES_V2_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        cartridgeTypev2: action.payload,
      };
    case types.CARTRIDGE_TYPE_EDIT:
      return {
        ...state,
        editedCartridgeType: action.payload,
      };

    default:
      return state;
  }
};

export default cartridgeTypeReducer;
