import React from "react";
import InstructionsComponent from "../Components/CartridgeInstructions/index";

import DashboardLayout from "../Components/Layouts/DashboardLayout";

const InstructionsPage = () => {
    return (
        <DashboardLayout title="Cartridge instructions">
            <InstructionsComponent />
        </DashboardLayout>
    );
};

export default InstructionsPage;
