import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Label } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Layout
import ModalFormLayout from "../Layouts/ModalFormLayout";

// Actions
import { addAlternative } from "../../redux/alternatives/alternatives.actions";
import { closeFormModal } from "../../redux/modals/modals.actions";
import { fetchIngredients } from "../../redux/ingredients/ingredients.actions";

const CartridgeAlternativeNewForm = () => {
  const [mainCartridgeType, setMainCartridgeType] = useState(null);
  const [substitutedCartridgeTypes, setSubstitutedCartridgeTypes] = useState(
    []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchIngredients());
  }, [dispatch]);

  const { register, handleSubmit, errors } = useForm();

  const cartridgeType = useSelector((state) => state.cartridgeType);
  const { loading, success, cartridgeTypes } = cartridgeType;

  const cTypes = cartridgeTypes.map((cartridgeType) => {
    return {
      ...cartridgeType,
      key: cartridgeType._id,
      text: `${cartridgeType.name} (${cartridgeType._id})`,
      value: cartridgeType._id,
    };
  });

  const onSubmit = (data) => {
    dispatch(
      addAlternative({
        cartridgeTypeId: mainCartridgeType,
        typesToSubstitute: substitutedCartridgeTypes,
      })
    );
    dispatch(closeFormModal());
  };

  const handleMainChange = (e, change) => {

    console.log("value", change.value);

    setMainCartridgeType(change.value);
  };

  const handleSubChange = (e, change) => {
    const value = change.value;

    console.log("value", value);
    setSubstitutedCartridgeTypes((prev) => {
      return [...prev, ...value];
    });
    setSubstitutedCartridgeTypes(value);
  };

  return (
    <ModalFormLayout title="New Cartridge Alternative">
      <Form
        noValidate
        size="large"
        loading={loading}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* // two selects, one for cartridge type , one for another another cartridge type. A new one appears once the second one is selected */}

        <Form.Select
          fluid
          label="Main Cartridge Type"
          options={cTypes}
          placeholder="Cartridge Type"
          onChange={handleMainChange}
        />
        <Form.Select
          fluid
          label="Substituted Cartridge Types"
          multiple
          options={cTypes}
          placeholder="Cartridge Type"
          onChange={handleSubChange}
        />
        <p>Substituted Cartridge Types will be hidden in portal</p>

        <Form.Button content="Submit" onClick={() => {}} />
      </Form>
    </ModalFormLayout>
  );
};

export default CartridgeAlternativeNewForm;
