import React from "react";
import { Route, Redirect } from "react-router-dom";

import FullPageLoader from "../Components/Loaders/FullPageLoader";
import { INGREDIENTS} from "./paths";

const UnprotectedRoute = ({
  component: Component,
  isAuthenticated,
  loading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      loading ? (
        <FullPageLoader />
      ) : isAuthenticated ? (
        <Redirect
          to={{
            pathname: `${INGREDIENTS}`,
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default UnprotectedRoute;
