import React from "react";
import DrinksComponent from "../Components/Drinks/";

import DashboardLayout from "../Components/Layouts/DashboardLayout";

const DrinksPage = () => {
  return (
    <DashboardLayout title="Drinks | H2Y0">
      <DrinksComponent />
    </DashboardLayout>
  );
};

export default DrinksPage;
