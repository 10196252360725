import React from "react";
import { Route, Redirect } from "react-router-dom";

// Components
import FullPageLoader from "../Components/Loaders/FullPageLoader";

// Paths
import { HOME } from "./paths";

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  loading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      loading ? (
        <FullPageLoader />
      ) : isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `${HOME}`,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default ProtectedRoute;
