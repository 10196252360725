import React from "react";
import { Button, Popup } from "semantic-ui-react";

const ActionButtonGroup = ({ id, onDelete, onEdit }) => (
  <Button.Group basic size="small">
    <Popup content="Edit" trigger={<Button icon="edit outline" onClick={() => onEdit(id)}/>} />
    {/* <Popup content="Edit" trigger={<Button icon="edit outline" />} onClick={() => onEdit(id)} /> */}
    <Popup
      content="Delete"
      trigger={<Button icon="delete" onClick={() => onDelete(id)} />}
    />
  </Button.Group>
);

export default ActionButtonGroup;
