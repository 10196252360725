const form = {
  NEW_INGREDIENT: "NEW_INGREDIENT",
  EDIT_INGREDIENT: "EDIT_INGREDIENT",
  
  NEW_CARTRIDGE_TYPE: "NEW_CARTRIDGE_TYPE",
  EDIT_CARTRIDGE_TYPE: "EDIT_CARTRIDGE_TYPE",
  
  NEW_CARTRIDGE_ALTERNATIVE: "NEW_CARTRIDGE_ALTERNATIVE",
  EDIT_CARTRIDGE_ALTERNATIVE: "EDIT_CARTRIDGE_ALTERNATIVE",
  
  NEW_LANGUAGE: "NEW_LANGUAGE",
  EDIT_LANGUAGE: "EDIT_LANGUAGE",
  EDIT_LANG_STRUCTURE: "EDIT_LANG_STRUCTURE",

  SECONDARY_DATA: "SECONDARY_DATA",
};

export default form;
