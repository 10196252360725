import React, { useEffect, useState } from "react";
import { Header, Table } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Popup, Modal, List } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import { fetchCartridgeTypes,fetchCartridgeTypesV2,updateCartridgeTypesV2, deleteCartridgeType, setEditedCartridgeType } from "../../redux/cartridgeType/cartridgeType.actions";
import { openFormModal } from "../../redux/modals/modals.actions";

// Form Constants
import form from "../../redux/modals/constants";

import Row from "./row"

const CartridgesV2Component = () => {
  const dispatch = useDispatch();
  const { cartridgeType } = useSelector((state) => state);
    const { ingredients } = useSelector((state) => state);

    const { loading, error } = cartridgeType;
    
    const { register, handleSubmit, errors } = useForm();

  const onDeleteCrt = (id) => {
    dispatch(deleteCartridgeType(id))
  }

  const onEdit = (id, data) => {
      console.log(id)
      const selected = cartridgeType.cartridgeTypev2.filter((val) => {
          return val._id === id;
      })
      console.log(data)
    dispatch(updateCartridgeTypesV2(selected[0], data))
    setTimeout(() => {
      dispatch(fetchCartridgeTypesV2());
    },200)
    // dispatch(openFormModal(form.EDIT_CARTRIDGE_TYPE))
  }

  useEffect(() => {
    dispatch(fetchCartridgeTypesV2());
  }, []);

  if (error) {
    toast.error(error);
  }

  let renderCartridgeType;

  if (cartridgeType.cartridgeTypev2 && cartridgeType.cartridgeTypev2.length) {
    renderCartridgeType = cartridgeType.cartridgeTypev2.map((value) => {
      let ingredientsContained = ""

    //   if (value.ingredients && value.ingredients.length) {
    //     ingredientsContained = value.ingredients.map((ingredient) => {
    //       return `${ingredient.name} - (${ingredient.quantity} ${ingredient.unit}), `;
    //     });
    //   } else {
    //     ingredientsContained = "None";
    //   }
        
        // setTimeout(() => {
        //     setDosingSpeed(value.dosingSpeed)
        //     setFlowRate(value.flowRate)
        //     setServingSize(value.servingSize)
        // },500)

      return (
          <Row value={value} onEdit={ onEdit}/>
      );
    });
  } else {
    renderCartridgeType = (
      <Table.Row>
        <Table.Cell>No cartridge types found.</Table.Cell>
      </Table.Row>
    );
  }
  return (
    <DashboardContentLayout
      title="Cartridge Types"
      loading={loading || ingredients.loading}
      // openAddModal={() => dispatch(openFormModal(form.NEW_CARTRIDGE_TYPE))}
    >
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Cartridge code(4 digit)</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>DosingSpeed (0 - 1)</Table.HeaderCell>
            <Table.HeaderCell>
              <div style={{"display":'flex ',"flexDirection":"row", "justifyContent":"space-around"}}>
              <div>Dose1 (Milisecs)</div>
              <div>Dose2 (Milisecs)</div>
              <div>Dose3 (Milisecs)</div>
              </div>
            </Table.HeaderCell>
            {/* <Table.HeaderCell>Serving Size</Table.HeaderCell>
            <Table.HeaderCell>Flow Rate</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderCartridgeType}</Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default CartridgesV2Component;
