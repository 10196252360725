import React from "react";
import { Container, Modal } from "semantic-ui-react";

import LoginForm from "../Components/Forms/LoginForm";

const HomePage = () => {
  return (
    <Container>
      <Modal open={true} size="tiny">
        <Modal.Header>H2YO Cloud Login</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <LoginForm />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Container>
  );
};

export default HomePage;
