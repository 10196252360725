import React from "react";
import { Modal } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { closeFormModal } from "../../redux/modals/modals.actions";

// Render form component
import FormToRender from "../Forms/FormToRender";

const ModalLayout = () => {
  const dispatch = useDispatch();

  const { modals } = useSelector((state) => state);

  const { isFormModalOpen, form } = modals;

  return (
    <Modal
      closeIcon
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={() => dispatch(closeFormModal())}
      open={isFormModalOpen}
      size={['NEW_LANGUAGE', 'EDIT_LANGUAGE'].includes(form) ? "large" : "tiny" }
    >
      <FormToRender value={form} />
    </Modal>
  );
};

export default ModalLayout;
