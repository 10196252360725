import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

// Paths
import * as path from "../../routes/paths";

const SideNav = () => {
    return (
        <Menu secondary vertical size="huge">
            <Menu.Item name="Ingredients" as={Link} to={path.INGREDIENTS} />
            <Menu.Item name="Cartridge Type" as={Link} to={path.CARTRIDGES} />
            <Menu.Item name="Cartridge Alternatives" as={Link} to={path.ALTERNATIVES} />
            <Menu.Item name="Drinks" as={Link} to={path.DRINKS} />
            <Menu.Item name="Internationalization" as={Link} to={path.I18N} />
            <Menu.Item name="Cartridge Instructions" as={Link} to={path.INSTRUCTIONS} />
        </Menu>
    );
};

export default SideNav;
