import React from "react";
import { Helmet } from "react-helmet";
import { Container, Grid } from "semantic-ui-react";

// Components
import SideNav from "../Menu/SideNav";
import TopNav from "../Menu/TopNav";

const DashboardLayout = ({ children, title }) => {
  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TopNav />
      <Grid>
        <Grid.Column width={4}>
          <SideNav />
        </Grid.Column>

        <Grid.Column stretched width={12}>
          {children}
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default DashboardLayout;
