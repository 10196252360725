const types = {
    GET_ALL_DRINKS: "GET_ALL_DRINKS",
    DRINKS_LOADING: "DRINKS_LOADING",
    DRINKS_LOADING_ERROR: "DRINKS_LOADING_ERROR",
    CLEAR_ERRORS: "CLEAR_ERRORS",
    DRINKS_LOADING_SUCCESS: "DRINKS_LOADING_SUCCESS",
    TOGGLE_DRINK_MODAL: "TOGGLE_DRINK_MODAL",
  EDIT_DRINK_MODAL: "EDIT_DRINK_MODAL",
  SECONDARY_DRINK_MODAL:"SECONDARY_DRINK_MODAL",
  };
  
  export default types;
  