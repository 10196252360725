import types from "./types";

const initialState = {
  loading: false,
  error: null,
  success: null,
  ingredients: [],
  editedIngredient: null,
};

const ingredientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case types.CLEAR_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case types.INGREDIENT_NEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case types.INGREDIENT_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "New ingredient added",
      };
    case types.INGREDIENT_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not create new ingredient",
        success: null,
      };
    case types.INGREDIENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
        ingredients: [],
      };
    case types.INGREDIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Could not fetch ingredients, please try again",
        success: null,
        ingredients: [],
      };
    case types.INGREDIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        ingredients: action.payload,
      };
    case types.INGREDIENTS_EDIT:
      return {
        ...state,
        editedIngredient: action.payload,
      };
    case types.INGREDIENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: "Ingredient Deleted Successfully",
      };

    default:
      return state;
  }
};

export default ingredientsReducer;
