import React from "react";
import { Segment, Header, Divider, Button, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";

const DashboardContentLayout = ({ title, children, loading, openAddModal, openEditStructureModal }) => {
  return (
    <>
      <Segment color="green" loading={loading}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Header as="h1">{title}</Header>
          {openEditStructureModal && (
            <Popup
              content="Add to structure"
              trigger={<Button icon="edit" onClick={openEditStructureModal} />}
            />
          )}
          {openAddModal && (
            <Popup
              content="Create new"
              trigger={<Button icon="add" onClick={openAddModal} />}
            />
          )}
        </div>

        <Divider />
        <Segment.Inline>{children}</Segment.Inline>
      </Segment>
    </>
  );
};

export default DashboardContentLayout;
