import React, { useEffect } from "react";
import { Header, Table, Label, Checkbox } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import { editIngredient, fetchIngredients } from "../../redux/ingredients/ingredients.actions";
import { openFormModal } from "../../redux/modals/modals.actions";
import { setEditedIngredient, deleteIngredient } from "../../redux/ingredients/ingredients.actions";

// Form Constants
import form from "../../redux/modals/constants";

const IngredientsComponent = () => {
    const dispatch = useDispatch();
    const { ingredients } = useSelector((state) => state);

    const { loading, error, success } = ingredients;

    useEffect(() => {
        dispatch(fetchIngredients());
    }, [dispatch]);

    if (error) {
        toast.error(error);
    }

    const onEdit = (id) => {
        dispatch(setEditedIngredient(id))
        dispatch(openFormModal(form.EDIT_INGREDIENT))
    }

    const updateIngredientShown = (ingredient, value) => {
        dispatch(editIngredient({ ...ingredient, showInTable: value }))
    }

    const onDelete = (id) => {
        console.log(id)
        dispatch(deleteIngredient(id))
    }

    if (success) {
        if (success === "Ingredient Deleted Successfully") {
            toast.success(success);
        }
    }

    let renderUsers;
    if (ingredients.ingredients && ingredients.ingredients.length) {
        renderUsers = ingredients.ingredients.map((user) => {
            let cartridgesContained;

            if (user.cartridge && user.cartridge.length) {
                cartridgesContained = user.cartridge.map((value) => {
                    console.log("Value is isss", value)
                    return (
                        <Label.Group color="green" key={Math.random()}>
                            <Label>{value ? value.name : null}</Label>
                        </Label.Group>
                    );
                });
            } else {
                cartridgesContained = <Label color="red">None</Label>;
            }
            return (
                <Table.Row key={user._id}>
                    <Table.Cell>{user._id}</Table.Cell>
                    <Table.Cell>
                        <Header as="h4" image>
                            <Header.Content>{user.name}</Header.Content>
                        </Header>
                    </Table.Cell>
                    <Table.Cell>{user.unit}</Table.Cell>
                    <Table.Cell>{user.createdAt}</Table.Cell>
                    <Table.Cell>
                        <Checkbox
                            defaultChecked={user.showInTable || false}
                            toggle
                            onChange={(e, { checked }) => updateIngredientShown(user, checked)}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <ActionButtonGroup id={user._id} onEdit={onEdit} onDelete={onDelete} />
                    </Table.Cell>
                </Table.Row>
            );
        });
    } else {
        renderUsers = (
            <Table.Row>
                <Table.Cell>No users found.</Table.Cell>
            </Table.Row>
        );
    }
    return (
        <DashboardContentLayout
            title="Ingredients"
            loading={loading}
            openAddModal={() => dispatch(openFormModal(form.NEW_INGREDIENT))}
        >
            <Table basic="very" celled collapsing size="small">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Cartridge type id</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Unit</Table.HeaderCell>
                        <Table.HeaderCell>Created At</Table.HeaderCell>
                        <Table.HeaderCell>Show in table</Table.HeaderCell>
                        <Table.HeaderCell>Cartridges contained</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{renderUsers}</Table.Body>
            </Table>
        </DashboardContentLayout>
    );
};

export default IngredientsComponent;
