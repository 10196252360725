const types = {
  INGREDIENTS_REQUEST: "INGREDIENTS_REQUEST",
  INGREDIENTS_FAILURE: "INGREDIENTS_FAILURE",
  INGREDIENTS_SUCCESS: "INGREDIENTS_SUCCESS",

  INGREDIENT_NEW_REQUEST: "INGREDIENT_NEW_REQUEST",
  INGREDIENT_NEW_SUCCESS: "INGREDIENT_NEW_SUCCESS",
  INGREDIENT_NEW_FAILURE: "INGREDIENT_NEW_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",

  INGREDIENTS_EDIT: "INGREDIENTS_EDIT",

  INGREDIENT_DELETE_SUCCESS: "INGREDIENT_DELETE_SUCCESS"
};

export default types;
